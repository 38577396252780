import styled from 'styled-components'
import CustomLink from '../CustomLink'
import siteTheme from '../../layout/theme'

export const StyledLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  padding: 1.1rem ${siteTheme.gutter};
  outline: none;
  border-radius: ${siteTheme.buttonRadius};
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: pointer;
  font-family: ${siteTheme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
  text-transform: uppercase;
  white-space: nowrap;
  
  &:focus {
    outline: none;
  }
`

export const themes = {
  cta: {
    backgroundColor: siteTheme.colorAccent,
    textColor: siteTheme.textcolorPrimary
  },
  dark: {
    backgroundColor: siteTheme.colorBlack,
    textColor: siteTheme.textcolorSecondary
  },
  white: {
    backgroundColor: siteTheme.textcolorSecondary,
    textColor: siteTheme.colorBlack
  }
}