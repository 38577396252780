import React from 'react'
import {
  Container, Heading, Text, Link, Arrow
} from './styles'

const FacilityMatchmaker = () => (
  <Container>
    <Link href={`${window?.location?.pathname}#vill-du-veta-mer`}>
      <Heading>Inte rätt lokal?</Heading>
      <Text>Fyll i detta formulär med dina önskemål och preferenser, så återkommer vi med förslag.</Text>
      <Arrow id="matchmaker-arrow" />
    </Link>
  </Container>
)

export default FacilityMatchmaker