import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import {
  Wrapper,
  ProfileImage,
  StyledLink,
  Info,
  InfoItem
} from './styles'

const ContactInfo = ({ textColor }) => {
  const {
    name,
    phone = '',
    email = '',
    profileImage
  } = useStaticQuery(contactInfoQuery).datoCmsContactInformation || {}

  return (
    <ThemeProvider theme={{ contactInfoTheme: { textColor } }}>
      <Wrapper>
        <ProfileImage url={profileImage.url} />
        <Info>
          {name && <InfoItem>{name}</InfoItem>}
          {phone && <StyledLink as="a" href={`tel:${phone}`}>{phone}</StyledLink>}
          {email && <StyledLink as="a" href={`mailto:${email}`}>{email}</StyledLink>}
        </Info>
      </Wrapper>
    </ThemeProvider>
  )
}

ContactInfo.propTypes = {
  textColor: PropTypes.string
}

ContactInfo.defaultProps = {
  textColor: 'black'
}

export default ContactInfo

export const contactInfoQuery = graphql`
  query ContactInformationQuery {
    datoCmsContactInformation {
      email
      name
      profileImage {
        url
      }
      phone
    }
  }
`
