import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_API_KEY } from '../../config'
var scrollToElement = require('scroll-to-element');
import { withTheme } from 'styled-components'
import {
  Container,
  Grid,
  ControlsItem,
  MapItem,
  Heading,
  IconOptions,
  StyledIconOption,
  Form,
  InputGroup,
  Submit,
  Arrow,
} from './styles'
import createMapOptions from '../../utils/createMapOptions'
import { trimUnits } from '../../utils/helpers'
import { POSITION, LATITUDE, LONGITUDE } from '../../config'
import Marker from '../Marker'
import removeNewLines from '../../utils/removeNewLines'
import DirectionDetails from '../DirectionDetails'
import DirectionInputAutocomplete from '../DirectionInputAutocomplete'

const DirectionsMap = ({ theme }) => {
  const [destination, setDestination] = useState('')
  const [travelMode, setTravelMode] = useState('TRANSIT')
  const [startMarker, setStartMarker] = useState({
    lat: LATITUDE,
    lng: LONGITUDE,
    text: 'Det Vita Huset'
  })
  const [endMarker, setEndMarker] = useState(false)
  const [API, setAPI] = useState(false)
  const [route, setRoute] = useState(null)
  const [address, setAddress] = useState(null)
  const [details, setDetails] = useState({})

  // Subscribe to destination, travel mode and when the API is ready
  useEffect(
    () => {
      setDirections()
    },
    [destination, travelMode, API],
  )

  function setDirections() {
    // Quit early if not our dependencies our done
    if (!API || !destination) {
      return
    }

    const { maps, map } = API
    const directionsService = new maps.DirectionsService()

    // Lookup our destination
    directionsService.route({
      origin: { lat: LATITUDE, lng: LONGITUDE },
      destination,
      travelMode
    }, (response, status) => {
      // Check that we have a route
      // Todo: should we care if we have multiple routes?
      if (status === 'OK' && response?.routes[0]?.overview_path) {

        if (route) {
          route.setMap(null)
        }

        const {
          routes: [
            {
              overview_path: path,
              legs: [
                {
                  start_location: startPosition,
                  start_address: startAddress,
                  end_location: endPosition,
                  end_address: endAddress,
                  duration,
                  distance
                }
              ],
              bounds
            }
          ]
        } = response

        // Create a custom polyline showing the route
        const polyline = new google.maps.Polyline({
          path,
          strokeColor: '#000000',
          strokeOpacity: 1.0,
          strokeWeight: 3
        })


        setAddress(removeNewLines(endAddress))
  
        // Set our custom markers for the route, the positions functions needs to be invoked otherwise
        // they will continuesly update positions.
        setStartMarker({
          lat: startPosition.lat(),
          lng: startPosition.lng(),
          text: 'Det Vita Huset'
        })

        setEndMarker({
          lat: endPosition.lat(),
          lng: endPosition.lng(),
          text: endAddress.split(',')[0]
        })

        // Recenter our map so the route is visible in the viewport
        map.fitBounds(response.routes[0].bounds)

        // Print our route
        polyline.setMap(map)
        setRoute(polyline)

        // Set route details from API response to local state
        setDetails({
          startAddress,
          endAddress,
          duration: duration.text,
          distance: distance.text
        })

        // Scroll geography block into view (only mobile)
        if (window.innerWidth <= theme.breakpoints.phone) {
          scrollToElement('#geography', {
            // Set top offset to headerheight (negative value)
            offset: (trimUnits(theme.headerheightMobile) * trimUnits(theme.fontsizeRoot) - 2) * -1 
          })        
        }

      } else {
        // Todo: handle address that dont work and so on
        console.log(`Directions request failed due to ${status}`)
      }
    })
  } 

  // Save our GMAP information
  const handleAPI = (map, maps) => {
    setAPI({
      map,
      maps
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (address && address != destination) {
      setDestination(address)
    }
  }

  const travelOptions = [
    { value: 'TRANSIT', label: 'Kollektivtrafik', icon: 'bus'},
    { value: 'DRIVING', label: 'Bil', icon: 'car' },
    { value: 'BICYCLING', label: 'Cykla', icon: 'bike' },
    { value: 'WALKING', label: 'Gå', icon: 'shoe' }
  ]

  return (
    <Grid>
      <ControlsItem>
        { !destination ? (
          <Form onSubmit={handleSubmit}>
            <Heading>Beräkna avståndet från din plats till Det Vita Huset</Heading>
            <IconOptions>
              { travelOptions.map((option, index) => (
                <StyledIconOption
                  key={option.label}
              // eslint-disable-next-line react/jsx-props-no-spreading
                  {...option}
                  onChange={setTravelMode}
                  checked={travelMode === option.value}
                  name="traveloptions"
                />
              ))}
            </IconOptions>
            <InputGroup>
              <DirectionInputAutocomplete
                label="Ange din adress"
                name="adress"
                theme="light"
                value={address}
                handleResponse={setDestination}
                origin={POSITION}
              />
              <Submit
                type="submit"
              >
                <Arrow />
              </Submit>
            </InputGroup>
          </Form>
        ) : (
          <DirectionDetails
            travelOptions={travelOptions}
            setTravelMode={setTravelMode}
            travelMode={travelMode}
            details={details}
            resetDestination={() => {
              setAddress('')
              setDestination()
            }}
          />
        )}

      </ControlsItem>
      <MapItem>
        <Container>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_MAPS_API_KEY
            }}
            defaultCenter={POSITION}
            defaultZoom={15}
            options={createMapOptions}
            onGoogleApiLoaded={({ map, maps }) => handleAPI(map, maps)}
          >
            <Marker
              key="start"
              text={startMarker.text}
              lat={startMarker.lat}
              lng={startMarker.lng}
            />
            { endMarker ? (
              <Marker
                key="end"
                text={endMarker.text}
                lat={endMarker.lat}
                lng={endMarker.lng}
              />
            ) : null }
          </GoogleMapReact>
        </Container>
      </MapItem>
    </Grid>
  )
}


export default withTheme(DirectionsMap)