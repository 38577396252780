import React from 'react'
import {
  Container, Header, Heading, List, Item, ContactImage, ContactImageContainer
} from './styles'
import createSrcSet from '../../utils/createSrcSet'
import createSizes from '../../utils/createSizes'

const FacilityContact = ({ administrator }) => {
  const {
    name, title, mobile, email, thumbnail, sizes
  } = administrator

  const image = thumbnail ? {
    base64: thumbnail.data,
    aspectRatio: thumbnail.aspectRatio,
    src: sizes[0].url,
    srcSet: createSrcSet(sizes),
    sizes: createSizes`(max-width: ${'phone'}px) 100vw, (max-width: ${'large'}px) calc(50vw - ${'gutterSmall'} * 1.5), calc(33vw - ${'gutterSmall'} * 2)`
  } : {}

  return (
    <Container>
      <Header>
        <Heading>Kontakt</Heading>
        <ContactImageContainer>
          <ContactImage fluid={image} />
        </ContactImageContainer>
      </Header>
      <List>
        <Item><strong>{name}</strong></Item>
        <Item>{title}</Item>
        <Item>Novier</Item>
        <Item><a href={`tel:${mobile}`}>{mobile}</a></Item>
        <Item><a href={`mailto:${email}`}>{email}</a></Item>
      </List>
    </Container>
  )
}

export default FacilityContact