import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { TextSection, Wrapper } from './styles'

const Subpage = ({ data }) => {
  const {
    seoMetaTags
  } = data.datoCmsPage

  return (
    <Layout seo={seoMetaTags.tags}>
      <Wrapper>
        <TextSection>
          <h3>Vi värnar om din integritet. För Novier AB inklusive helägda dotterbolag ("Novier") är det viktigt att du ska känna dig trygg med att vi behandlar dina personuppgifter på ett säkert och lagligt sätt.</h3>
          <p>
            All behandling av personuppgifter sker enligt gällande lagstiftning. Detta innebär bland annat att Novier skyddar dina personuppgifter med nödvändiga åtgärder och att du alltid har rätt att kontakta oss för att få reda på vilka personuppgifter som vi har sparade om dig. Du kan läsa mer om dina rättigheter i denna policy. Du kan också besöka www.imy.se där du kan läsa mer om dina rättigheter avseende vår behandling av dina personuppgifter.
            <br />
            <br />
            Har du några frågor om vår behandling av personuppgifter är du alltid välkommen att kontakta oss!
          </p>
          <h4>Integritetspolicy</h4>
          <p>Denna integritetspolicy beskriver hur Novier behandlar dina personuppgifter.</p>

          <h4>Vem ansvarar för dina personuppgifter?</h4>
          <p>Novier AB org.nr. 559337-6568 inklusive helägda dotterbolag, är personuppgiftsansvarig för behandlingen av dina personuppgifter och ansvarar för att sådan behandling sker på ett lagligt sätt.</p>
          <h4>Hur får vi dina personuppgifter?</h4>
          <p>Novier lagrar personuppgifter som du lämnar till oss när du tex hyr en lägenhet, lokal eller parkering eller står som kontaktperson för ett företag. I samband med kontraktsskrivning godkänns behandlingen av personuppgifter. Novier lagrar även personuppgifter om du söker ett jobb hos oss, när du eller ditt företag tar kontakt med oss för att hitta en ledig lokal eller när du i övrigt använder dig av Noviers olika tjänster.</p>
          <h4>Vilka personuppgifter behandlas?</h4>
          <p>Novier behandlar de personuppgifter som lämnas till oss och/eller inhämtas av oss. Det kan vara namn, e-postadress, telefonnummer, adressuppgifter, födelsedatum, personnummer, bankuppgifter, CV, personligt brev och/eller annan information som du lämnat till Novier eller som i övrigt är nödvändig för att Novier ska kunna fullgöra sina åtaganden och sin service i övrigt gentemot dig.</p>
          <h4>Varför behandlar vi personuppgifter om dig?</h4>
          <p>
            Dina personuppgifter kan komma att behandlas av Novier för bland annat följande ändamål:
          </p>
          <ul>
            <li>för att kontakta dig</li>
            <li>för att fullgöra våra åtaganden i enlighet med de tjänster som du använder dig av från Novier och vår service i övrigt gentemot dig</li>
            <li>för att möjliggöra god service till dig genom att exempelvis besvara frågor som du ställer till oss</li>
            <li>för att skicka relevant information och erbjudanden om Noviers olika tjänster och arrangemang i egenskap av hyresvärd till dig som kund</li>
            <li>för att använda som underlag vid hyresavisering</li>
            <li>för att hantera eventuella betalningsförsummelser</li>
            <li>för att följa tillämplig lagstiftning såsom bokföringslagen</li>
            <li>för intern identifikation såsom kontraktsnummer, kundnummer</li>
            <li>för att hantera och administrera arbetsansökningar</li>
          </ul>
          <h4>När har vi rätt att behandla dina personuppgifter?</h4>
          <p>Novier har rätt att behandla de personuppgifter som krävs för fullgörandet av Noviers åtagande mot dig, om du har lämnat samtycke till behandlingen eller om behandlingen anses kunna ske enligt ett berättigat intresse för Novier, enligt ovan. Novier kan även behöva behandla dina personuppgifter på grund av krav i lag eller förordning.</p>
          <h4>Vem har tillgång till dina personuppgifter?</h4>
          <p>
            Dina personuppgifter används av Novier samt i tillämpliga fall av de samarbetspartners Novier samarbetar med för att fullgöra åtaganden gentemot Noviers kunder. Novier lämnar inte ut personuppgifter till andra bolag eller myndigheter om Novier inte är skyldig att göra det på grund av lag, förordning eller myndighetsbeslut eller för att skydda våra rättigheter eller tredje parts rättigheter eller om utlämnandet till tredje part följer av våra åtaganden gentemot dig.
            <br />
            <br />
            Novier vidarebefordrar eller säljer aldrig dina personuppgifter för marknadsföringsändamål till tredje part.
          </p>
          <h4>Överföring till tredje land</h4>
          <p>Novier överför inte dina personuppgifter till länder utanför EU/EES om inte detta särskilt angetts i samband med att du lämnar dina personuppgifter till oss.</p>
          <h4>Hur länge sparar vi dina personuppgifter?</h4>
          <p>Novier sparar dina personuppgifter endast så länge det är nödvändigt för att Novier ska uppfylla ändamålet med behandlingen i varje enskilt fall.</p>
          <h4>Kamerabevakning:</h4>
          <p>Novier har laglig grund i ett berättigat intresse med den kamerabevakning som sker i och runt vissa av de fastigheter som förvaltas av Novier, bland annat med hänsyn till att förebygga skadegörelse, upptäcka brott eller säkerställa säkerhet och ordning i och omkring en byggnad. Novier följer Fastighetsägarna och SABOs vägledning för kamerabevakning som anses vara god sed på marknaden. Vi gallrar som huvudregel inspelat material efter två veckor.</p>
          <h4>Vad har du för rättigheter?</h4>
          <p>
            Du har rätt att när som helst (kostnadsfritt en gång om året) begära information om vilka personuppgifter Novier har sparat om dig (registerutdrag). Din begäran ska vara skriftlig och egenhändigt undertecknad samt innehålla uppgift om namn och personnummer/identifikationsnummer.
            <br />
            <br />
            Om dina personuppgifter är felaktiga, ofullständiga eller irrelevanta, kan du begära att få dem rättade. Du har även rätt att få dina personuppgifter raderade. Novier kan dock inte radera dina personuppgifter om det föreligger ett lagstadgat krav på lagring, till exempel bokföringsregler, eller när det finns andra legitima skäl till varför personuppgifterna måste sparas.
            <br />
            <br />
            Begäran om registerutdrag, information, rättning av felaktiga, ofullständiga eller irrelevanta personuppgifter och radering av personuppgifter skickas till Novier, Box 7043, 103 86 Stockholm.
            <br />
            <br />
            Du har vidare rätt att i förekommande fall inge klagomål till berörd tillsynsmyndighet för behandling av personuppgifter (i Sverige; Integritetsskyddsmyndigheten).
          </p>
          <h4>Hur skyddas dina personuppgifter?</h4>
          <p>Novier arbetar ständigt med att vidta alla lämpliga tekniska och organisatoriska åtgärder som i varje enskilt fall krävs för att skydda dina personuppgifter och i övrigt säkerställa att behandlingen sker enligt gällande lagstiftning.</p>
          <h4>Uppdatering av denna integritetspolicy</h4>
          <p>Novier gör löpande ändringar i denna integritetspolicy. En ny version blir gällande när den görs tillgänglig på vår hemsida.</p>
          <h4>Cookies</h4>
          <p>
            Cookies används på så gott som alla webbplatser och kan vara nödvändiga för att de ska fungera. Vi använder cookies för att förbättra och förenkla ditt besök på vår webbplats. En cookie är en liten textfil som sparas på din dator när du besöker en webbplats.
            <br />
            <br />
            Det finns olika typer av cookies, såsom varaktiga och temporära (sessionscookies), som vi använder oss av. Varaktiga cookies sparas på din dator under en viss tid tills du eller servern raderar dem. Sessionscookies sparas tillfälligt på din dator och upphör när du stänger ner din webbläsare eller enhet.
          </p>
          <h4>Så här använder Novier cookies</h4>
          <p>
            På novier.se använder vi cookies för att förbättra de tjänster vi erbjuder dig. Genom att veta hur du, genom ditt besök på vår webbplats, använder våra tjänster kan vi utveckla, anpassa och förbättra våra tjänster efter dina behov.
            <br />
            <br />
            Vi använder oss av tredjepartscookies, såsom Google Analytics, för att samla in statistik och information samt göra analyser om ditt besök i syfte att bättre förstå hur vår webbplats används. Vi använder också cookies för att kunna erbjuda dig relevanta tjänster och funktioner.
          </p>
          <h4>Möjlighet att styra användningen av cookies</h4>
          <p>
            Vill du inte acceptera cookies kan du enkelt justera inställningarna för din webbläsare så att du automatiskt blockerar lagring av cookies eller meddelas varje gång en webbplats begär att få lagra en cookie på din dator. Genom att ändra inställningarna i din webbläsare kan du också radera tidigare lagrade cookies. Vill du göra en tillfällig blockering av cookies på en webplats väljer du att öppna ett nytt privat fönster i webbläsaren, oftast genom att högerklicka och välja Nytt Inprivate/Inkognito-fönster.
            <br />
            <br />
            Ha i åtanke att om du inte accepterar cookies kommer prestandan och funktionalitet på vår webbplats att försämras.
            <br />
            <br />
            Vidare information om cookies hittar du på post- och telestyrelsens webbplats: <a href="https://pts.se">pts.se</a>.
          </p>
        </TextSection>
      </Wrapper>
    </Layout>
  )
}

export default Subpage;

export const query = graphql`
  query SubpageQuery {
    datoCmsPage(id: {eq: "DatoCmsPage-L8kGthJERuuCVtlNYoFQDA-sv"}) {
      pageBlocks {
        __typename
        ...HeroBlockFragment
        ...TextBlockFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`