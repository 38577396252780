import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import CTA from '../CTA'
import Text from '../blocks/Text'

export const Warning = styled(Text).attrs(({ theme }) => ({
  textColor: theme.colorBlack,
  backgroundColor: theme.colorAccent
}))`
    padding-top: ${({ theme }) => theme.gutterSmall};
    padding-bottom: ${({ theme }) => theme.gutterSmall};
    height: 100%;
    display: flex;
    align-items: center;
`

export const Wrapper = styled.article`
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
`

export const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
export const StyledCTA = styled(CTA)`
  position: absolute;
  bottom: ${({ theme }) => theme.gutterSmall};
  right: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('phone')`
    top: ${({ theme }) => theme.gutter};
    right: ${({ theme }) => theme.gutter};
    bottom: auto;
  `}
`