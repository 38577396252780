import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 46px 16px;
  max-width: 1000px;

  h2 {
    max-width: 55rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 32px;
    max-width: 55rem;
    margin-bottom: 2rem;
  }
  
  h4 {
      font-size: 32px;
      max-width: 55rem;
      margin-bottom: .7rem;
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    list-style: outside;
    padding-left: 36px;
    color: black;
    margin-bottom: 2rem;

    li {
      margin-bottom: 12px;
      padding-left: 10px;
      display: list-item;
    }
  }

  a {
    text-decoration: underline;
    &:hover {
      opacity: .6;
    }
  }

  ${breakpoint.up('phone')`
    padding: 64px 42px;
  `}
  
  ${breakpoint.up('tablet')`
    padding: 90px;
  `}
`