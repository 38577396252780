import React, { useState } from "react";
import { Collapse } from "@mantine/core";
import { useScrollIntoView, useElementSize } from "@mantine/hooks";
import Button from "../Button";
import {
  StyledButton,
  SmallImage,
  IMG,
  Grid,
  Cover,
  CoverImage,
  Center,
  Container
} from "./styles";
import createSrcSet from '../../utils/createSrcSet'
import createSizes from '../../utils/createSizes'

// eslint-disable-next-line react/display-name
export const ImageGallery = React.forwardRef(({ images }, ref) => {
  const [opened, setOpened] = useState(false);
  const { scrollIntoView, targetRef } = useScrollIntoView();
  const { ref: refSize, height } = useElementSize();

  const transformedImages = images.map((image) => ({
      src: image.sizes[0].url,
      srcSet: createSrcSet(image.sizes),
      sizes: createSizes`(max-width: ${'phone'}px) 100vw, (max-width: ${'large'}px) calc(50vw - ${'gutterSmall'} * 1.5), calc(33vw - ${'gutterSmall'} * 2)`
  }))

  return (
    <Container>
      <Grid ref={ref}>
        <SmallImage
          ref={refSize}
          src={transformedImages[0]?.src}
          srcSet={transformedImages[0]?.srcSet}
        />
        <SmallImage src={transformedImages[1]?.src} srcSet={transformedImages[1]?.srcSet} />
      </Grid>
      <Cover height={height}>
        <CoverImage src={transformedImages[2]?.src} srcSet={transformedImages[2]?.srcSet} />
        <StyledButton
          onClick={() => {
            setOpened((o) => !o);
          }}
          text={opened ? "Dölj bilder" : `Alla bilder (${transformedImages.length})`}
        />
      </Cover>
      <Collapse in={opened} onTransitionEnd={() => scrollIntoView()}>
        <div ref={targetRef} />
        {transformedImages.map((image) => (
          <IMG key={image?.src} src={image?.src} srcSet={image?.srcSet} />
        ))}
        <Center>
          <Button
            onClick={() => {
              setOpened((o) => !o);
            }}
            text="Dölj bilder"
            />
        </Center>
      </Collapse>
    </Container>
  );
});

export default ImageGallery;
