import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Halign } from '../../../layout/mixins'
import {
  Section,
  Grid,
  TextItem,
  ImageItem,
  Image,
  TextContent,
  Title,
  BodyText
} from './styles'


const FeatureHightlight = ({
  title,
  content,
  image,
  reverse
}) => {

  return (
    <Section id="reportage">
      <Halign>
        <Grid>
          <TextItem reverse={reverse}>
            <TextContent>
              <Title as="h2">{title}</Title>
              <BodyText className="rte" dangerouslySetInnerHTML={{ __html: content }} />
            </TextContent>
          </TextItem>
          <ImageItem reverse={reverse}>
            <Image fluid={image.fluid} />
          </ImageItem>
        </Grid>
      </Halign>
    </Section>
  )
}

FeatureHightlight.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  reverse: PropTypes.bool
}

FeatureHightlight.defaultProps = {
  title: '',
  content: '',
  image: {
    fluid: {}
  },
  reverse: false
}

export default FeatureHightlight

export const REPORTAGE_BLOCK_FRAGMENT = graphql`
  fragment ReportageBlockFragment on DatoCmsReportageBlock {
    reportage {
      title
      content
      image {
        fluid(maxWidth: 1280) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    reverse
  }
`