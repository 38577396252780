import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import busIcon from '../../layout/icons/bus.svg'
import boatIcon from '../../layout/icons/boat.svg'
import carIcon from '../../layout/icons/car.svg'

export const Wrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
`

export const GridItem = styled.div`
  padding: ${({ theme }) => theme.gutterMedium};

  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => theme.gutterXLarge};
`}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 5.33rem;
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1.67rem;
  font-weight: 500;
  line-height: 1.167em;
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const Bus = styled(GridItem)`
  background: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorBlack};
  flex: 1;
`

export const BusIcon = styled(busIcon)`
  width: 3.83rem;
  height: 3.83rem;
  fill: ${({ theme }) => theme.colorBlack};
`

export const Boat = styled(GridItem)`
  background: ${({ theme }) => theme.colorGreylight};
  color: ${({ theme }) => theme.colorBlack};
  flex: 1;
`

export const BoatIcon = styled(boatIcon)`
  width: 5.33rem;
  height: 5.33rem;
  fill: ${({ theme }) => theme.colorBlack};
`

export const Car = styled(GridItem)`
  background: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.colorWhite};

  a {
    color: ${({ theme }) => theme.colorWhite};
    text-decoration: underline;
    
    &:hover {
      opacity: .6;
    }
  }

  ${breakpoint.down('desktop')`
    width: 100%;
  `}

  ${breakpoint.up('desktop')`
    flex: 1;
  `}
`

export const CarIcon = styled(carIcon)`
  width: 4.94rem;
  height: 4.94rem;
  fill: ${({ theme }) => theme.colorWhite};
`

export const Info = styled.div``