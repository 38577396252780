import styled from 'styled-components'
import { breakpoint, Heading4Styles } from '../../layout/mixins'

export const Container = styled.article``

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
`

export const List = styled.ul`
  margin-top: ${({ theme }) => theme.gutter};
  border-top: 1px solid ${({ theme }) => theme.colorBlack};
`
export const Item = styled.li`
  padding: ${({ theme }) => theme.gutterSmall} 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colorBlack};
`

export const Headline = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.colorBlack};
  padding-bottom: ${({ theme }) => theme.gutterSmall};
`

export const Prefix = styled.span`
  display: block;
  width: 50%;
`
export const Text = styled.span`
  display: block;
  width: 50%;
`

export const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.gutter};
`
export const Box = styled.article`
  width: 100%;
  background: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => theme.gutterSmall};
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    width: calc(50% - ${({ theme }) => theme.gutterSmall});
    margin-bottom: 0;
  `}
`

export const DetailedList = styled.ul`
  font-size: ${({ theme }) => theme.fontsizeSmall};
`
export const DetailedRow = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.gutterSmall};

  ${({ spaced, theme }) => spaced && `
    padding-top: ${theme.gutter};
  `}
`

export const DetailedItem = styled.span`
  flex-grow: 1;
  width: 25%;
    
  &:first-child {
    flex-grow: 0;
    width: 50%
  }
`