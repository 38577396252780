/* global google */
import React, { useEffect, useState } from 'react'
import {
  Section,
  Grid,
  ControlsItem,
  MapItem,
  StyledBlockMenu
} from './styles'
import DirectionsMap from '../../DirectionsMap'

const Geography = () => (
  <Section id="geography">
    {/* <StyledBlockMenu /> */}
    <DirectionsMap />
  </Section>
)

export default Geography