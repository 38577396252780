import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint, Heading1 } from '../../../layout/mixins'
import Video from '../../Video'

export const StyledHero = styled.section`
  position: relative;
  width: 100%;

  ${breakpoint.down('tablet')`
    heigt: 0;
    padding-top: 100%;

    && a {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    && a:last-of-type {
      transform: translate(-50%, calc(50% + 
        ${({ theme }) => theme.gutterSmall}));
    }
  `}
`

export const HeroInner = styled.div`
  ${breakpoint.down('tablet')`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `}

  ${breakpoint.up('max')`
    height: 80vh;
  `}
`

export const BackgroundImage = styled(Img)`
  width: 100%;
  height: 100%;
`

export const StyledVideo = styled(Video)`
    ${breakpoint.up('max')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    `}
`

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.gutter};

  ${breakpoint.up('max')`
    padding: 0 ${({ theme }) => theme.gutterXXLarge};
  `}
`

export const LeftCol = styled.div`
  width: 50%;
`

export const ButtonWrapper = styled.div`
  display: flex;

  && a {
    margin-right: ${({ theme }) => theme.gutterSmall};
  }

  && a:last-child {
    margin-right: 0;
  }
`

export const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colorWhite};
  margin-bottom: ${({ theme }) => theme.gutter};
`