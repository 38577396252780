import React, { useState, useEffect } from 'react'
import { request } from 'graphql-request'
import { graphql, Link } from 'gatsby'
import useErrorBoundary from 'use-error-boundary'
import siteTheme from '../../layout/theme'
import Layout from '../../components/Layout'
import Text from '../../components/blocks/Text'
import FacilityListingItem from '../../components/FacilityListingItem'
import { VITEC_GQL, PROPERTY_GUID } from '../../config'
import {
  Grid, Warning, StyledSpinner
} from './styles'

export default ({ data }) => {
  const {
    seoMetaTags,
    textContent
  } = data.datoCmsFacilityPage

  const { ErrorBoundary, didCatch, error } = useErrorBoundary()
  const [objects, setObjects] = useState([])
  const [isFailed, setIsFailed] = useState(false)
  const [cursor, setCursor] = useState('')
  const [hasNextPage, sethasNextPage] = useState(true)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    document.body.classList.add('full-height')
    return function cleanup() {
      document.body.classList.remove('full-height')
    }
  }, [])

  const facilitiesQuery = /* GraphQL */ `
    query getFacilities($propertyGuid: String!, $first: Int!, $after: String) {
      facilities(propertyGuid: [$propertyGuid], first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            guid
            slug
            objectType
            area
            streetAddress
            admittanceDate
            earliastAdmittance
            yearlySqmRent
            settings {
              showRent
            }
            USP {
              headline
              shortDescription
              longDescription
            }
            posterImage {
              thumbnail {
                data
                aspectRatio
              }
              sizes {
                url
                name
                width
              }
            }
          }
        }
      }
    }
  `
  const facilitiesVariables = {
    propertyGuid: PROPERTY_GUID,
    first: 100,
    after: cursor
  }


  useEffect(() => {
    const getObjects = async () => {
      try {
        const response = await request(VITEC_GQL, facilitiesQuery, facilitiesVariables)
        setCursor((prev) => response.facilities.pageInfo.endCursor)
        sethasNextPage((prev) => response.facilities.pageInfo.hasNextPage)
        setObjects((prevObjects) => ([...prevObjects, ...response.facilities.edges]))
        setLoading(false)
      } catch (error) {
        setIsFailed(true)
        setLoading(false)
      }
    }
    getObjects()
  }, [])

  const renderObjects = () => {
    if (isLoading) {
      return (<StyledSpinner key={0} />)
    }

    return objects.map((item) => (
      <Link to={`/lediga-lokaler/${item.node.guid}/${item.node.slug}`} key={item.node.guid}>
        <FacilityListingItem
              // eslint-disable-next-line react/jsx-props-no-spreading
          {...item.node}
        />
      </Link>
    ))
  }

  function Error() {
    return (
      <Warning
        text="Det gick inte att ladda in objekt"
      />
    )
  }

  return (
    <Layout
      seo={seoMetaTags.tags}
      disableFooter
    >
      <Text
        text={textContent}
        textColor={siteTheme.colorAccent}
        backgroundColor="black"
      />
      {didCatch || isFailed ? (
        <Error />
      ) : (
        <Grid>
          <ErrorBoundary>
            {renderObjects()}
          </ErrorBoundary>
        </Grid>
      )}

    </Layout>
  )
}

export const query = graphql`
  query FacilityQuery {
    datoCmsFacilityPage {
      textContent
      seoMetaTags {
        tags
      }
    }
  }
`