import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'


export const Grid = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const GridItem = styled.article`
  width: 100%;


  ${breakpoint.up('phone')`
    width: 50%;
  `}

  ${breakpoint.up('desktop')`
    width: 25%;
  `}
`
