import styled from 'styled-components'
import {
  breakpoint,
  Heading3,
  ParagraphLargeStyles,
  BlockPaddingBoth,
  TruncateOverflow
} from '../../../layout/mixins'
import Img from 'gatsby-background-image'
import DottedArrow from '../../../layout/icons/dotted-arrow.svg'

export const Section = styled(BlockPaddingBoth)`
  background: ${({ theme }) => theme.colorGreylight};
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
  `}
`

export const TextItem = styled(GridItem)`
  display: flex;
  justify-content: center;
  order: 1;

  ${breakpoint.up('tablet')`
    order: ${({ reverse }) => reverse ? '2' : '1'};
  `}
`

export const ImageItem = styled(GridItem)`
  position: relative;
  order: 2;
  width: 87%;

  ${breakpoint.up('tablet')`
    order: ${({ reverse }) => reverse ? '1' : '2'};
  `}

  ${breakpoint.up('desktop')`
    width: 45%;
  `}

  ${breakpoint.up('max')`
    width: 50%;
  `}
`

export const Image = styled(Img)`
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  ${breakpoint.up('desktop')`
    padding-top: 0;
    height: 100%;
  `}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('desktop')`
    padding-bottom: 0;
    max-width: 28rem;

    ${({ reverse, theme }) => reverse ? `
      padding-left: ${theme.gutterLarge};
    ` : `
      padding-right: ${theme.gutterLarge}
    `}
  `}
`

export const Title = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const BodyText = styled.div`
  ${ParagraphLargeStyles}
  p {
    ${ParagraphLargeStyles}
    ${TruncateOverflow}

    ${({ theme }) => `
      @media (min-width: ${theme.breakpoints.tablet}) {
        // font-size: 1.33rem;
      }
    `}
  }

  p:not(:first-child) {
    display: none;
  }  
`

export const Arrow = styled(DottedArrow)`
  position: absolute;
  width: 5.39rem;
  height: 6.5rem;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  fill: ${({ theme }) => theme.colorBlack};
`