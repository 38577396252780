import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import Text from '../../components/blocks/Text'
import Spinner from '../../components/Spinner'

export const StyledSpinner = styled(Spinner)`
  background: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => theme.gutterLarge};
`

export const Grid = styled.article`
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme }) => theme.gutterSmall};
  grid-row-gap: ${({ theme }) => theme.gutterSmall};
  padding: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint.up('large')`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpoint.up('maxWrapper')`
    padding-left: 0;
    padding-right: 0;
  `}
`

export const Warning = styled(Text).attrs(({ theme }) => ({
  textColor: theme.colorWhite,
  backgroundColor: theme.colorDanger
}))`
    padding-top: ${({ theme }) => theme.gutterSmall};
    padding-bottom: ${({ theme }) => theme.gutterSmall};
`
