import styled, { css } from 'styled-components'
import { breakpoint, Heading4Styles } from '../../layout/mixins'

export const Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};

  ${breakpoint.down('tablet')`
    &:last-child {
      padding-top: 0;
    }
  `}

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => `0px ${theme.gutterLarge} ${theme.blockpadding} ${theme.gutterLarge}`};
  `}

  ${breakpoint.up('maxWrapper')`
    padding: ${({ theme }) => `0px 7rem ${theme.blockpadding} 7rem`};
  `}
`

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
  margin-bottom: 24px;

  ${breakpoint.up('desktop')`
    margin-bottom: 40px;
  `}
`

export const Amenities = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  font-size: 16px;

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint.up('tablet')`
    grid-template-columns: repeat(3, 1fr);
    font-size: 18px;
  `}
  
  ${breakpoint.up('large')`
      grid-template-columns: repeat(4, 1fr);
  `}
`

export const AmenityWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 24px;
  background:  ${({ theme }) => theme.colorAccent};
  line-height: 1em;
  font-weight: 500;
`

export const IconStyle = css`
  width: 36px;
  height: 36px;
  margin-right: 16px;
  flex-shrink: 0;
  vertical-align: middle;
  overflow-clip-margin: content-box;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint.up('tablet')`
    grid-template-columns: repeat(3, 1fr);
    font-size: 18px;
  `}
  
  ${breakpoint.up('large')`
      grid-template-columns: repeat(4, 1fr);
      margin-right: 24px;
  `}
`