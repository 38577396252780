// Coordinates for the main location to show on maps
export const LATITUDE = 59.313037

export const LONGITUDE = 18.101267

export const POSITION = [LATITUDE, LONGITUDE]

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBP4OiGgrmYEv19k0BlP1eVRBqsnfliSwI'

export const VITEC_GQL = 'https://newapi.detvitahuset.se/graphql'
// export const VITEC_GQL = 'https://api.novire.se/graphql'

export const PROPERTY_GUID = '4H0A239TSE251AIS'

export const SITE_URL = 'https://www.detvitahuset.se'