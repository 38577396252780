import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import { Halign } from '../../layout/mixins'

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: ${({ theme: { headerheightMobile, gutter } }) => `calc(${headerheightMobile} + ${gutter})`};
  background: ${({ theme }) => theme.colorGrey};
  z-index: ${({ theme }) => theme.zLevel2};
  display: none;

  .menu-open & {
    display: block;
  }

  ${breakpoint.up('desktop')`
    .menu-open & {
      display: none;
    }
  `}
`

export const StyledHalign = styled(Halign)`
    height: 100%;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.gutter};
`

export const NavList = styled.ul``

export const NavItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 30px;
  line-height: 1.1em;
  margin-bottom: ${({ theme }) => theme.gutter};
  cursor: pointer;

  && a {
    color: ${({ theme }) => theme.textcolorPrimary};
  }
`