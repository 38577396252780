import React from 'react'
import {
  Heading, Container, List, Item, Prefix, Headline, Text, Boxes, Box, DetailedList, DetailedRow, DetailedItem
} from './styles'
import formateDate from '../../utils/formatDate'

const FacilityFacts = ({
  objectType, area, earliastAdmittance, admittanceDate, areaRent, settings, certification
}) => {
  const {
    yearlySqmRent,
    heatIncluded,
    yearlySqmHeatRent,
    electricityIncluded,
    yearlySqmElectricityRent,
    waterIncluded,
    yearlySqmWaterRent,
    facilityTaxesIncluded,
    yearlyFacilityTaxes,
    coolingIncluded,
    yearlySqmRentCooling,
    customRent,
  } = areaRent[0]
  const { showRent } = settings
  return (
    <Container>
      <Heading>Fakta</Heading>
      <List>
        { objectType ? (
          <Item>
            <Prefix>Lokaltyp</Prefix>
            <Text>{objectType}</Text>
          </Item>
        ) : null }
        <Item>
          <Prefix>Storlek</Prefix>
          <Text>
            {`${area} m`}
            <sup>2</sup>
          </Text>
        </Item>
        <Item>
          <Prefix>Miljömärkning</Prefix>
          <Text>{certification}</Text>
        </Item>
      </List>
      <Boxes>
        <Box>
          <Headline>
            <Prefix>Hyra</Prefix>
            { showRent ? (
              <Text>
                {`${yearlySqmRent} kr/m`}
                <sup>2</sup>
              </Text>
            ) : (
              <Text>Vid förfrågan</Text>
            )}

          </Headline>
          <DetailedList>

            { heatIncluded && yearlySqmHeatRent > 0 ? (
              <DetailedRow>
                <DetailedItem>Värme</DetailedItem>
                <DetailedItem>
                  {`${yearlySqmHeatRent} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${heatIncluded})`}</DetailedItem>
              </DetailedRow>
            ) : null}

            { electricityIncluded && yearlySqmElectricityRent > 0 ? (
              <DetailedRow>
                <DetailedItem>El</DetailedItem>
                <DetailedItem>
                  {`${yearlySqmElectricityRent} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${electricityIncluded})`}</DetailedItem>
              </DetailedRow>
            ) : null}

            { waterIncluded && yearlySqmWaterRent > 0 ? (
              <DetailedRow>
                <DetailedItem>Vatten</DetailedItem>
                <DetailedItem>
                  {`${yearlySqmWaterRent} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${waterIncluded})`}</DetailedItem>
              </DetailedRow>
            ) : null}

            { coolingIncluded && yearlySqmRentCooling > 0 ? (
              <DetailedRow>
                <DetailedItem>Vatten</DetailedItem>
                <DetailedItem>
                  {`${yearlySqmRentCooling} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${coolingIncluded})`}</DetailedItem>
              </DetailedRow>
            ) : null}

            { facilityTaxesIncluded && yearlyFacilityTaxes > 0 ? (
              <DetailedRow>
                <DetailedItem>Fastighetsskatt</DetailedItem>
                <DetailedItem>
                  {`${yearlyFacilityTaxes} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${facilityTaxesIncluded})`}</DetailedItem>
              </DetailedRow>
            ) : null}

            {customRent.map(({ text, included, yearlySqmRent }) => (
              <DetailedRow key={text}>
                <DetailedItem>{text}</DetailedItem>
                <DetailedItem>
                  {`${yearlySqmRent} kr/m`}
                  <sup>2</sup>
                </DetailedItem>
                <DetailedItem>{`(${included})`}</DetailedItem>
              </DetailedRow>
            ))}

            <DetailedRow spaced>Moms tillkommer på ovanstående belopp</DetailedRow>
          </DetailedList>
        </Box>
        <Box>
          <Headline>Tillträde</Headline>
          <DetailedList>
            <DetailedRow>{admittanceDate != null ? formateDate(admittanceDate) : earliastAdmittance}</DetailedRow>
          </DetailedList>
        </Box>
      </Boxes>
    </Container>
  )
}

export default FacilityFacts