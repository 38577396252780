import styled from 'styled-components'
import {
  breakpoint,
  Heading3,
  BlockPaddingBoth,
  ParagraphLargeStyles
} from '../../../layout/mixins'
import Img from 'gatsby-image'

export const Section = styled(BlockPaddingBoth)`
  
  ${breakpoint.up('tablet')`
    padding-top: ${({ theme }) => theme.blockpaddingMobile};
  `}

  ${breakpoint.up('desktop')`
    padding-top: ${({ theme }) => theme.blockpadding};
    padding-bottom: ${({ theme }) => theme.blockpadding};
  `}
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('large')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  align-items: center;
`

export const TextItem = styled(GridItem)`
  display: flex;
  justify-content: center;
  order: 1;

  ${breakpoint.up('large')`
    width: calc(100% * 1/3);
    order: ${({ reverse }) => reverse ? '2' : '1'};
  `}
`

export const MediaItem = styled(GridItem)`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  order: 2;

  ${breakpoint.up('phone')`
    padding-top: 56.25%;
  `}

  ${breakpoint.up('large')`
    width: calc(100% * 2/3);
    padding-top: 44.25%;
    order: ${({ reverse }) => reverse ? '1' : '2'};
  `}
`

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;               
`

export const Image = styled(Img)`
  width: 100%;
  height: 100%;

  ${breakpoint.up('desktop')`
    height: auto;
  `}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28rem;
  padding-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('desktop')`
    max-width: 40rem;
    padding-bottom: ${({ theme }) => theme.blockpadding};
  `}

  ${breakpoint.up('large')`
    max-width: 28rem;
    padding-bottom: 0;

    ${({ reverse, theme }) => reverse ? `
      padding-left: ${theme.gutterLarge};
    ` : `
      padding-right: ${theme.gutterLarge}
    `}
  `}
`

export const Title = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const BodyText = styled.div`
  p {
    ${ParagraphLargeStyles}
  }
`
