import styled from 'styled-components'
import { breakpoint, Heading4Styles } from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/usp-arrow.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorBlack};
  padding: ${({ theme }) => theme.gutterMedium};
`

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
  width: 100%;
`

export const Text = styled.p`
  margin-top: ${({ theme }) => theme.gutter};
  flex-grow: 1;
`

export const Link = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const Arrow = styled(ArrowIcon)`
  width: 3em;
  height: 3em;
  fill: ${({ theme }) => theme.colorBlack};
  margin-top: ${({ theme }) => theme.gutter};
  animation-name: bounce;
  transform-origin: center bottom;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-delay: 2s;
`