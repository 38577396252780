import styled from 'styled-components'

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
`

export const NavListItem = styled.li`

`
