import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { request } from 'graphql-request'
import { Link } from 'gatsby'
import useErrorBoundary from 'use-error-boundary'
import {
  Warning, Wrapper, Inner, StyledCTA
} from './styles'
import Slider from '../Slider'
import { VITEC_GQL, PROPERTY_GUID } from '../../config'
import FacilityListingItem from '../FacilityListingItem'
import Spinner from '../Spinner'

const AvaliableFacilities = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [facilities, setFacilities] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  const facilitiesQuery = /* GraphQL */ `
    query getFacilities($propertyGuid: String!, $first: Int!) {
      facilities(propertyGuid: [$propertyGuid], first: $first) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            guid
            slug
            objectType
            area
            streetAddress
            admittanceDate
            earliastAdmittance
            yearlySqmRent
            settings {
              showRent
            }
            USP {
              headline
              shortDescription
              longDescription
            }
            posterImage {
              thumbnail {
                data
                aspectRatio
              }
              sizes {
                url
                name
                width
              }
            }
          }
        }
      }
    }
  `
  const facilitiesVariables = {
    propertyGuid: PROPERTY_GUID,
    first: 6
  }


  useEffect(() => {
    const fetchObject = async () => {
      setIsLoading(true)

      try {
        const response = await request(VITEC_GQL, facilitiesQuery, facilitiesVariables)
        setFacilities(response.facilities.edges)
        setIsLoading(false)
      } catch (error) {
        setIsFailed(true)
        setIsLoading(false)
      }
    }

    fetchObject()
  }, [])

  function Error() {
    return (
      <Warning
        text="Det gick inte att hämta våra senaste lediga lokaler."
      />
    )
  }
  return (
    <Wrapper>
      <Inner>
        {didCatch || isFailed ? (
          <Error />
        )
          : isLoading ? (
            <Spinner />
          ) : (
            <>
              <Slider
                arrowStyle="dotted"
                autoPlay
                onSlideChange={(index) => setCurrentIndex(index)}
              >
                {facilities ? facilities.map((item) => (
                  <Link to={`/lediga-lokaler/${item.node.guid}/${item.node.slug}`} key={item.node.guid}>
                    <ErrorBoundary>
                      <FacilityListingItem
                    // eslint-disable-next-line react/jsx-props-no-spreading
                        {...item.node}
                        noBackground
                        noHover
                      />
                    </ErrorBoundary>
                  </Link>
                )) : null}
              </Slider>
              <StyledCTA
                text="Alla lokaler"
                url="/lediga-lokaler"
              />
            </>
          )}
      </Inner>
    </Wrapper>
  )
}

export default AvaliableFacilities