import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Section,
  Grid,
  TextItem,
  ImageItem,
  StyledBackgroundImage,
  ImageIndex,
  TextContent,
  Title,
  BodyText,
  CTAButton
} from './styles'
import Slider from '../../Slider'


const FeatureHightlight = ({
  title,
  bodyText,
  images,
  reverse,
  ctaText,
  ctaUrl,
  ctaOnTop
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <Section>
      <Grid>
        <TextItem reverse={reverse}>
          <TextContent>
            <Title as="h2">{title}</Title>
            <BodyText dangerouslySetInnerHTML={{ __html: bodyText }} />
          </TextContent>
        </TextItem>
        <ImageItem reverse={reverse}>
          <Slider onSlideChange={(index) => setCurrentIndex(index)}>
            {images.map((item) => (
              <StyledBackgroundImage key={item.fluid.src} fluid={item.fluid} />
            ))}
          </Slider>
          {images.length > 1 && <ImageIndex reverse={reverse}>{`${currentIndex + 1}/${images.length}`}</ImageIndex>}
        </ImageItem>
        {ctaText && (
          <CTAButton
            icon="dotted-arrow"
            text={ctaText}
            url={ctaUrl}
            ctaOnTop={ctaOnTop}
          />
        )}
      </Grid>
    </Section>
  )
}

FeatureHightlight.propTypes = {
  title: PropTypes.string,
  bodyText: PropTypes.string,
  images: PropTypes.array,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaOnTop: PropTypes.bool,
  reverse: PropTypes.bool
}

FeatureHightlight.defaultProps = {
  title: '',
  bodyText: '',
  images: [],
  ctaText: '',
  ctaUrl: '',
  ctaOnTop: false,
  reverse: false
}

export default FeatureHightlight

export const FEATURE_HIGHLIGHT_BLOCK_FRAGMENT = graphql`
  fragment FeatureHighlightBlockFragment on DatoCmsFeatureHighlightBlock {
    title
    bodyText
    images {
      fluid(maxWidth: 1280) {
        ...GatsbyDatoCmsFluid
      }
    }
    ctaText
    ctaUrl
    ctaOnTop
    reverse
  }
`