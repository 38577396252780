import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getAmenityIcon } from '../../layout/icons/amenities/getAmenityIcon'
import { AmenityWrapper, IconStyle } from './styles'


const Amenity = ({ amenityName }) => {
    const [IconComponent, setIconComponent] = useState(null);

    useEffect(() => {
        // code to run on component mount

        // If icon is set to false return early
        if (!amenityName) return

        // Import based on icon prop 
        getAmenityIcon(amenityName.trim()).then((module) => {
          const StyledIcon = styled(module.default)([IconStyle])
          setIconComponent(StyledIcon)
        })
    }, [])

  return (
    <AmenityWrapper>
        {IconComponent && <IconComponent />}
        {amenityName?.trim()}
    </AmenityWrapper>
  )
}

export default Amenity