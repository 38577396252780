import styled from 'styled-components'
import {
  breakpoint, Heading4Styles, ParagraphLargeStyles, ParagraphStyles
} from '../../layout/mixins'
import Arrow from '../../layout/icons/dotted-arrow.svg'
import CloseIcon from '../../layout/icons/close.svg'

export const Content = styled.article`
  &:first-child {
    margin-bottom: ${({ theme }) => theme.gutter};
    padding-bottom: ${({ theme }) => theme.gutter};
    border-bottom: 1px solid ${({ theme }) => theme.colorBlack};
  }
`

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
`
export const SubHeading = styled.h4`
  ${ParagraphLargeStyles}
  font-weight: 500;
  margin-top: ${({ theme }) => theme.gutterSmall};
`

export const ModalContent = styled.article`
  background: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => theme.gutterLarge};
  width: 50em;
  position: relative;
`

export const ModalClose = styled(CloseIcon)`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  stroke: ${({ theme }) => theme.colorBlack};
  width: 1.11rem;
  height: 1.11rem;
  z-index: ${({ theme }) => theme.zLevel4};
  cursor: pointer;

  ${breakpoint.up('desktop')`
    width: 2rem;
    height: 2rem;
  `}
`

export const Button = styled.button`
  ${ParagraphLargeStyles}
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.gutterMedium};

`

export const ButtonText = styled.span`
`

export const ButtonIcon = styled(Arrow)`
  width: 1em;
  height: 1em;
  margin-left: ${({ theme }) => theme.gutterSmall}
`
