import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import theme from '../../layout/theme'
import { outsideClick, trimUnits } from '../../utils/helpers'
import {
  Wrapper,
  Inner,
  Overlay,
  StyledSpinner,
  Iframe,
  BackgroundImage,
  OverlayContent,
  ContentCenter,
  StartButton,
  QuitButton,
  ChangeSrcButton,
  ChangeSrcIcon,
  Instructions,
  CornerImageWrapper,
  CornerImage
} from './styles'

const ThreeDTour = ({
  coverImage,
  thumbnail,
  instructions,
  linksToInteriors,
  enabled,
  noCover,
  className
}) => {
  const [activeInterior, setActiveInterior] = useState(0)
  const [tourEnabled, setTourEnabled] = useState(enabled)

  useEffect(() => {
    if (tourEnabled) {
      setNextInterior()
    }
  }, [activeInterior])

  // Start/stop the tour based on prop
  useEffect(() => {
    setTourEnabled(enabled)
    if (enabled) {
      startTour()
    } else {
      stopTour()
    }
  }, [enabled])


  // This has been removed due to not working properly. Now we remove class 'tour-open' on menu clicks instead.
  // Leaving code here for now in case something stopped working that I didn't notice straight away.
  // Initialize outside click helper
  // const closeOnOutsideClick = outsideClick('#tour', stopTour)

  // useEffect(() => {
  //   if (tourEnabled) {
  //     // Listen for outside click and add handler
  //     // to disbale tour if outside click is detected
  //     closeOnOutsideClick.listen()
  //   }
  //   return function cleanup() {
  //     // Remove click listener when not needed
  //     closeOnOutsideClick.remove()
  //   }
  // })

  // 3D tour state
  const [loadingState, setLoadingState] = useState({
    iframeLoaded: false,
    loading: false
  })

  // Save reference to iframe DOM node
  const iframe = React.createRef()

  function startTour() {
    // Add class to body for UI state
    // Setting fullwidthheight - headerheight
    document.body.classList.add('tour-open')

    // Scroll tour section into view
    if (window.innerWidth > theme.breakpoints.desktop) {
      // Timeout so the tour is visible when checking for its top position
      setTimeout(() => {
        const element = document.querySelector('#tour')
        const elementTop = element.getBoundingClientRect().top
        const bodyTop = document.body.getBoundingClientRect().top
        const headerHeight = trimUnits(theme.headerheight) * trimUnits(theme.fontsizeRoot)

        window.scroll({
          top: elementTop - bodyTop - headerHeight,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    }

    setLoadingState({
      iframeLoaded: false,
      loading: true
    })
    setTourEnabled(true)

    setTimeout(() => {
      setLoadingState({
        iframeLoaded: true,
        loading: false
      })
    }, 1500)
  }

  function switchTour() {
    const el = iframe.current
    el.addEventListener('load', () => {
      setTourEnabled(true)

      setTimeout(() => {
        setLoadingState({
          iframeLoaded: true,
          loading: false
        })
      }, 3000)
    })
    el.setAttribute('src', linksToInteriors[activeInterior].url)
    setLoadingState({
      iframeLoaded: false,
      loading: true
    })
  }

  function stopTour() {
    document.body.classList.remove('tour-open')
    document.body.classList.remove('tour-loaded')
    setTourEnabled(false)
  }

  function setNextInterior() {
    switchTour()
  }

  function calculateNextIndex() {
    const length = linksToInteriors.length - 1
    let nexInterior = activeInterior

    if (activeInterior >= length) {
      nexInterior = 0
    } else {
      nexInterior++
    }

    return nexInterior
  }

  function renderTour() {
    return (
      <Wrapper className={className} visble={tourEnabled || !noCover} id="tour">
        {/* Hide the component if there is no cover images/information but switch to visible if the tour is enabled */}
        <Inner>

          {!tourEnabled && !noCover && coverImage.fluid ? <BackgroundImage fluid={coverImage.fluid} /> : null}

          {!tourEnabled ? (
            <Overlay />
          ) : null}

          <StyledSpinner
            loading={loadingState.loading}
            tourEnabled={tourEnabled}
          />

          <OverlayContent>

            {tourEnabled && linksToInteriors.length > 1 && (
              <ChangeSrcButton
                onClick={() => setActiveInterior(calculateNextIndex())}
              >
                Byt inredning
                <ChangeSrcIcon />
              </ChangeSrcButton>
            )}

            {tourEnabled && (
              <QuitButton onClick={stopTour}>
                Avsluta tour
              </QuitButton>
            )}

            {!tourEnabled && !noCover ? (
              <ContentCenter>
                <Instructions dangerouslySetInnerHTML={{ __html: instructions }} />
                <StartButton text="Starta 360-tour" icon="spinner" onClick={startTour} />
                {thumbnail.fixed && (<CornerImageWrapper><CornerImage fixed={thumbnail.fixed} /></CornerImageWrapper>)}
              </ContentCenter>
            ) : null}

          </OverlayContent>

          <Iframe
            ref={iframe}
            frameborder="0"
            src={linksToInteriors[0].url}
            tourEnabled={tourEnabled}
          />
        </Inner>
      </Wrapper>

    )
  }

  return renderTour()
}

ThreeDTour.propTypes = {
  coverImage: PropTypes.shape({
    fluid: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ])
  }),
  thumbnail: PropTypes.shape({
    fluid: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ])
  }),
  instructions: PropTypes.string,
  linksToInteriors: PropTypes.arrayOf(PropTypes.object).isRequired,
  enabled: PropTypes.bool
}

ThreeDTour.defaultProps = {
  coverImage: {
    fluid: false
  },
  thumbnail: {
    fluid: false
  },
  instructions: null,
  enabled: false
}


export default ThreeDTour