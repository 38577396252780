import styled from 'styled-components'
import { breakpoint, Heading4Styles } from '../../layout/mixins'
import ExternalIcon from '../../layout/icons/external-link.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => theme.gutterMedium};
`

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
`

export const List = styled.ul`
  margin-top: ${({ theme }) => theme.gutter};
  border-top: 1px solid ${({ theme }) => theme.colorAccent};
`
export const Item = styled.li`
  padding: ${({ theme }) => theme.gutterSmall} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colorAccent};
`
export const Link = styled.a`
  display: block;
  flex-grow: 1;
  color: ${({ theme }) => theme.colorAccent};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Icon = styled(ExternalIcon)`
  width: 1em;
  height: 1em;
  fill: ${({ theme }) => theme.colorAccent};
  flex-basis: 1em;
`