import styled from 'styled-components'
import SpinnerIcon from '../../layout/icons/spinner.svg'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colorAccent};
  z-index: ${({ theme }) => theme.zLevel3};
`

export const Loader = styled(SpinnerIcon)`
  width: 2rem;
  height: 2rem;
  fill: black;

  @keyframes spinner {
    to {
      transform: rotate(-360deg);
    }
  }
  animation: spinner 1s linear infinite;
`
