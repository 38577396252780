import React from 'react'
import {
  Nav,
  StyledHalign,
  Content,
  NavList,
  NavItem
} from './styles'
import ContactInfo from '../ContactInfo'
import CustomLink from '../CustomLink'
import { toggleInquiry } from '../../utils/helpers'

const MenuMobile = ({ menuItems }) => (
  <Nav>
    <StyledHalign>
      <Content>
        <NavList>
          {menuItems.map(({ link }) => (
            <NavItem
              key={link.title}
              onClick={() => {
                document.body.classList.remove('menu-open');
                document.body.classList.remove('tour-open');
              }}
            >
              <CustomLink to={link.slug.startsWith("/") ? link.slug : `/${link.slug}`} activeClassName="active-link">
                {link.title}
              </CustomLink>
            </NavItem>
          ))}
          <NavItem onClick={() => {
            document.body.classList.remove('menu-open')
          }}
          >
            <CustomLink to="#vill-du-veta-mer">
              Vill du veta mer?
            </CustomLink>
          </NavItem>
        </NavList>
        <ContactInfo />
      </Content>
    </StyledHalign>
  </Nav>
)

MenuMobile.defaultProps = {
  menuItems: []
}

export default MenuMobile