import styled from 'styled-components'
import {
  breakpoint
} from '../../layout/mixins'
import DottedArrow from '../../layout/icons/dotted-arrow.svg'
import IconOption from '../IconOption'

export const Container = styled.article`
  width: 100%;
  height: 100%;
`

export const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
    height: auto;
    min-height: 40rem;
  `}
`

export const ControlsItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};

  ${breakpoint.down('phone')`
    min-height: 100vw;
  `}

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => theme.gutterLarge};
  `}
`

export const MapItem = styled(GridItem)`
  position: relative;
  order: 2;
  height: 100vw;
  
  ${breakpoint.up('phone')`
    height: calc(100vw * 0.5625);
  `}

  ${breakpoint.up('desktop')`
    height: auto;
    order: ${({ reverse }) => (reverse ? '1' : '2')};
  `}
`

export const Heading = styled.h4`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1.67rem;
  font-weight: 500;
  line-height: 1.167em;
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const IconOptions = styled.article`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.gutterSmall};

    ${breakpoint.up('tablet')`
      margin-right: ${({ theme }) => theme.gutter};
    `}
  }
`
export const StyledIconOption = styled(IconOption)`
  svg {
    width: 1.5rem;
    height: 1.5rem;

    ${breakpoint.up('tablet')`
      width: 3em;
      height: 3em;
    `}

    ${breakpoint.up('desktop')`
      width: 1.5rem;
      height: 1.5rem;
    `}

    ${breakpoint.up('max')`
      width: 3em;
      height: 3em;
    `}
  }
`

export const Form = styled.form`
  width: 100%;
  max-width: 30em;
`

export const InputGroup = styled.article`
  margin-top: ${({ theme }) => theme.gutterMedium};
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
`

export const Submit = styled.button`
`

export const Arrow = styled(DottedArrow)`
  width: 2em;
  height: 2em;
  flex: 0 0 2em;
  margin-left: ${({ theme }) => theme.gutter};
`
