import React from 'react'
import PropTypes from 'prop-types'
import { Halign } from '../../layout/mixins'
import {
  Container,
  Paragraph,
  Content
} from './styles'

const ErrorMessage = ({
  text,
  textColor,
  backgroundColor,
  className,
  children
}) => (
  <Container
    className={className}
    backgroundColor={backgroundColor}
    textColor={textColor}
  >
    {children}
  </Container>
)

ErrorMessage.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string
}

ErrorMessage.defaultProps = {
  text: '',
  textColor: 'black',
  backgroundColor: 'transparent'
}

export default ErrorMessage
