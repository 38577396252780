import styled from 'styled-components'
import {
  BlockPaddingBoth,
  ParagraphLargeStyles,
  breakpoint
} from '../../layout/mixins'


export const StyledText = styled(BlockPaddingBoth)`
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: ${({ theme }) => theme.maxWidth};
  text-align: center;

  p {
    ${ParagraphLargeStyles}
    color: ${({ textColor }) => textColor};
    text-align: center;
  
    ${breakpoint.up('desktop')`
      font-size: 1.6rem;
    `}
  
    & p {
      text-align: center;
    }
  }
`
