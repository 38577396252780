import styled from 'styled-components'
import Slider from 'react-slick'
import sliderArrow from '../../layout/icons/slider-arrow.png'
import dottedArrow from '../../layout/icons/dotted-arrow.png'
import { breakpoint } from '../../layout/mixins'

export const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;

  .slick-slide {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-track {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    height: 100%;
  }

  .slick-prev, .slick-next {
    z-index: ${({ theme }) => theme.zLevel1};
    display: block;
    background-repeat: no-repeat;

    ${({ arrowStyle, theme }) => (arrowStyle === 'default' ? `
        background-image: url(${sliderArrow});
        background-size: 2.39rem 2.61rem;
        width: 2.39rem;
        height: 2.61rem;
        opacity: 0.6;
    ` : `
        background-image: url(${dottedArrow});
        background-size: 2.39rem 1.96rem;
        background-color: ${theme.colorAccent};
        background-position: 50% 50%;
        width: 4rem;
        height: 3.5rem;
        opacity: 1;
 
    `)};

    ${({ arrowStyle }) => arrowStyle !== 'default' && breakpoint.down('tablet')`
      display: none !important;
    `}
    
    &:hover {
      opacity: 1;
    }
  }

  .slick-next {
    ${({ arrowStyle, theme }) => (arrowStyle === 'default' ? `
      right: ${theme.gutterMedium};
    ` : `
      right: 0;
    `)}

    transform: translate(0, -50%);
  }

  .slick-prev {
    ${({ arrowStyle, theme }) => (arrowStyle === 'default' ? `
      left: ${theme.gutterMedium};
    ` : `
      left: 0;
    `)}
    transform: rotate(180deg) translate(0, 50%);
  }

  .slick-next:before, .slick-prev:before {
    content: '';
  }

`
