import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Section,
  Grid,
  MosaicItem,
  AvaliableFacilitiesItem
} from './styles'
import Mosaic from '../../Mosaic'
import AvaliableFacilities from '../../AvaliableFacilities'

const Usp = (props) => {
  const {
    avaliableFacilities,
    ...mosaicProps
  } = props

  return (
    <Section id="lediga-ytor">
      <Grid>
        <MosaicItem>
          <Mosaic
            {...mosaicProps}
          />
        </MosaicItem>
        <AvaliableFacilitiesItem>
          <AvaliableFacilities
            avaliableFacilities={avaliableFacilities}
          />
        </AvaliableFacilitiesItem>
      </Grid>
    </Section>
  )
}

Usp.propTypes = {
  usp1: PropTypes.string,
  usp2: PropTypes.string,
  usp3: PropTypes.string,
  previewVideo: PropTypes.shape({
    url: PropTypes.string
  }),
  fullVideo: PropTypes.shape({
    url: PropTypes.string
  }),
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  ctaUrl: PropTypes.string,
  avaliableFacilities: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }))
}

Usp.defaultProps = {
  usp1: '',
  usp2: '',
  usp3: '',
  previewVideo: {
    url: ''
  },
  fullVideo: {
    url: ''
  },
  image: {
    fluid: {}
  },
  ctaUrl: '',
  avaliableFacilities: {
    title: '',
    url: ''
  }
}

export default Usp

export const USP_BLOCK_FRAGMENT = graphql`
  fragment UspBlockFragment on DatoCmsUspBlock {
    usp1
    usp2
    usp3
    previewVideo {
      url
    }
    fullVideoEmbedded {
      providerUid
    }
    image {
      fluid(maxWidth: 400) {
        ...GatsbyDatoCmsFluid
      }
    }
    ctaUrl
    avaliableFacilities {
      title
      url
    }
  }  
`