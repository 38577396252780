import styled, { css } from 'styled-components'
import styledBreakpoint from '@humblebee/styled-components-breakpoint'
import theme from './theme'

export const breakpoint = styledBreakpoint({
  phone: theme.breakpoints.phone,
  tablet: theme.breakpoints.tablet,
  desktop: theme.breakpoints.desktop,
  large: theme.breakpoints.large,
  max: theme.breakpoints.max,
  maxWrapper: theme.breakpoints.maxWrapper
})

export const Halign = styled.div`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: ${theme.gutterLarge};
    padding-right: ${theme.gutterLarge};
  `}
`

export const BlockPaddingTop = styled.section`
  padding-top: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
  `}
`

export const BlockPaddingBottom = styled.section`
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-bottom: ${theme.blockpadding};
  `}
`

export const BlockPaddingBoth = styled.section`
  padding-top: ${theme.blockpaddingMobile};
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
    padding-bottom: ${theme.blockpadding};
  `}
`

export const Heading1Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: 2.714rem;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('desktop')`
    font-size: 5rem;
  `}
`

export const Heading1 = styled.h1`
  ${Heading1Styles}
`

export const Heading2Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: 2.14rem;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('desktop')`
    font-size: 2.78rem;
  `}
`

export const Heading2 = styled.h2`
  ${Heading2Styles}
`

export const Heading3Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 2.14rem;
  line-height: 1em;
  font-weight: 500;
  margin: 0;
  text-transform: none;

  ${breakpoint.up('desktop')`
    font-size: 2.78rem;
  `}
`

export const Heading3 = styled.h3`
  ${Heading3Styles}
`

export const Heading4Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.67.rem;
  line-height: 1.27em;
  font-weight: 500;
  margin: 0;
`

export const Heading4 = styled.h3`
  ${Heading4Styles}
`

export const ParagraphStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  max-width: 55rem;
`

export const Paragraph = styled.p`
  ${ParagraphStyles}
`

export const ParagraphLargeStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: ${theme.fontsizeMobile};
  font-weight: 400;
  line-height: 1.3em;
  max-width: 55rem;

  ${breakpoint.up('tablet')`
    font-size: 1.33rem;
  `}
`

export const ParagraphLarge = styled.p`
  ${ParagraphLargeStyles}
`

export const TruncateOverflow = css`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`