import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint, Heading4Styles } from '../../layout/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorWhite};
  color: ${({ theme }) => theme.colorBlack};
  padding: ${({ theme }) => theme.gutterMedium};
`

export const Heading = styled.h3`
  ${Heading4Styles}
  font-size: ${({ theme }) => theme.fontsizeMedium};
  margin-right: ${({ theme }) => theme.gutter};
`
export const List = styled.ul``
export const Item = styled.li`
  line-height: ${({ theme }) => theme.lineheightRegular};
`

export const ContactImage = styled(Img)`
  border-radius: 50%; 
  max-width: ${({ theme }) => theme.portraitImageSize};
  max-height: ${({ theme }) => theme.portraitImageSize};
  margin-top: -${({ theme }) => theme.gutterSmall};
`


export const ContactImageContainer = styled.div`
  flex-basis: ${({ theme }) => theme.portraitImageSize};
  min-height: ${({ theme }) => theme.portraitImageSize};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`