import React from 'react'
import { Heading, Wrapper, Amenities } from './styles'
import Amenity from './Amenity.js'

const FacilityAmenities = ({ amenities }) => (
    <Wrapper>
      <Heading>Bekvämligheter</Heading>
      <Amenities>
        {amenities.map(amenityName => (
          <Amenity amenityName={amenityName} />
        ))}
      </Amenities>
    </Wrapper>
);

export default FacilityAmenities