import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoint } from '../../layout/mixins'
import HamburgerIcon from '../../layout/icons/hamburger.svg'
import CloseIcon from '../../layout/icons/close.svg'

export const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidthWrapper};
  height: ${({ theme }) => theme.headerheightMobile};
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.zLevel3};
  padding: ${({ theme }) => `0 ${theme.gutterSmall}`};
  background-color: ${({ theme }) => theme.colorWhite};

  .menu-open & {
    background-color: transparent;
  }

  .modal-open & {
    ${breakpoint.down('tablet')`
      display: none;
    `}
  }

  ${breakpoint.up('desktop')`
    height: ${({ theme }) => theme.headerheight};
    padding: ${({ theme }) => `0 ${theme.gutter}`};
    padding-right: 0;
  `}
`

export const Hamburger = styled(HamburgerIcon)`
  fill: ${({ theme }) => theme.colorBlack};
  width: 30px;

  .menu-open & {
    display: none;
  }

  ${breakpoint.up('desktop')`
    display: none;
  `}
`

export const Close = styled(CloseIcon)`
  stroke: ${({ theme }) => theme.colorBlack};
  display: none;

  .menu-open & {
    display: block;
  }
`

export const Logo = styled(Link)`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorBlack};

  ${breakpoint.up('tablet')`
    font-size: 2rem;
  `}

  ${breakpoint.up('large')`
    font-size: 2.5rem;
  `}
`