import styled from 'styled-components'
import Text from '../../components/blocks/Text'
import { breakpoint } from '../../layout/mixins'
import Spinner from '../../components/Spinner'

export const Warning = styled(Text).attrs(({ theme }) => ({
  textColor: theme.colorBlack,
  backgroundColor: theme.colorAccent
}))`
   min-height: calc(100vh - ${({ theme }) => theme.headerheightMobile});

  ${breakpoint.up('desktop')`
    min-height: calc(100vh - ${({ theme }) => theme.headerheight});
  `}
`

export const Grid = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`
export const GridItem = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};

  ${breakpoint.down('tablet')`
    &:last-child {
      padding-top: 0;
    }
  `}

  ${breakpoint.up('desktop')`
    width: 50%;
    height: auto;
    min-height: 40rem;
    padding: ${({ theme }) => `${theme.blockpadding} ${theme.gutterLarge}`};
  `}

  ${breakpoint.up('maxWrapper')`
    padding: ${({ theme }) => `${theme.blockpadding} 7rem`};
  `}

`
export const StyledSpinner = styled(Spinner)`
    background: ${({ theme }) => theme.colorWhite};
    padding: ${({ theme }) => theme.gutterLarge};
`