import React from 'react'
import { createGlobalStyle } from 'styled-components'

const RTEStyles = createGlobalStyle`
  .rte {
    h1 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 1em;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
`
export default RTEStyles