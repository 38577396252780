/* eslint-disable react/jsx-indent */
import React from 'react'
import { Location } from '@reach/router'
import {
  Nav,
  NavList,
  NavItem,
  InquiryContainer,
  InquiryLink,
  InquiryItem,
  CloseItem,
  StyledArrowIcon,
  StyledCloseIcon
} from './styles'
import CustomLink from '../CustomLink'

const Menu = ({ menuItems }) => (
    <Location>
      {({ location }) => (
        <Nav>
          <NavList>
            {menuItems.map(({ link }) => (
                <NavItem
                  key={link.title}
                  onClick={() => {
                    document.body.classList.remove('menu-open');
                    document.body.classList.remove('tour-open');
                  }}
                >
                  <CustomLink to={link.slug.startsWith("/") ? link.slug : `/${link.slug}`} activeClassName="active-link">
                    {link.title}
                  </CustomLink>
                </NavItem>
            ))}

              <InquiryContainer>
                {/* The Inquiry component adds a handler for the 'hashchange' event that will take care of toggling the Inquiry flyout  */}
                <InquiryLink to="#vill-du-veta-mer">
                  <InquiryItem as="span">
                    Vill du veta mer
                    <StyledArrowIcon />
                  </InquiryItem>
                  <CloseItem as="span">Stäng</CloseItem>
                  <StyledCloseIcon />
                </InquiryLink>
              </InquiryContainer>
          </NavList>
        </Nav>
      )}
    </Location>
)

Menu.defaultProps = {
  menuItems: []
}

export default Menu
