import styled from 'styled-components'
import {
  InputGroup,
} from '../Input/styles'


export const StyledInputGroup = styled(InputGroup)`
  &:after {
    background: black;
  }
`

export const DropdownContainer = styled.ul`
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: ${({ theme }) => theme.zLevel1};
  background: ${({ theme }) => theme.colorWhite};
  border-radius: ${({ theme }) => theme.contentRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  overflow: hidden;
`

export const DropdownItem = styled.li`
  cursor: pointer;
  background: ${({ theme, active }) => active ? theme.colorAccent : theme.colorWhite};
  padding: ${({ theme }) => `${theme.gutterSmall} ${theme.gutter}`};
  line-height: 1.3;

  &:first-of-type {
    padding-top: ${({ theme }) => theme.gutter};
  }

  &:last-of-type {
    padding-bottom: ${({ theme }) => theme.gutter};
  }
`

export const LoadingItem = styled(DropdownItem)``

export const ErrorItem = styled(DropdownItem)``