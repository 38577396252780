import siteTheme from '../layout/theme'

// Use template string to find and replace breakpoints and/or values from our theme
export default function createSizes(string, ...keys) {
  const { breakpoints } = siteTheme
  const result = [string[0]]
  keys.forEach((key, index) => {
    const replaced = breakpoints[key] || siteTheme[key]
    result.push(replaced, string[index + 1])
  })
  return result.join('')
}