import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Header, Hamburger, Logo, Close } from './styles'
import Menu from '../Menu'

export default ({ menuItems }) => {
  const siteData = useStaticQuery(siteQuery)
  const { name } = siteData.datoCmsSite

  const toggleBodyClass = () => {
    const body = document.querySelector('body')
    body.classList.toggle('menu-open')
  }

  return (
    <>
      <Header>
        <Logo to="/" onClick={() => document.body.classList.remove('tour-open')}>{name}</Logo>
        <Hamburger onClick={toggleBodyClass} />
        <Close onClick={toggleBodyClass} />
        <Menu menuItems={menuItems} />
      </Header>
    </>
  )
}

export const siteQuery = graphql`
  query SiteQuery {
    datoCmsSite {
      name
    }
  }
`