import styled from 'styled-components'
import {
  breakpoint,
  Heading3,
  ParagraphLarge
} from '../../../layout/mixins'
import BackgroundImage from 'gatsby-background-image'
import CTA from '../../CTA'

export const Section = styled.section``

export const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
    height: auto;
    min-height: 40rem;
  `}
`

export const TextItem = styled(GridItem)`
  display: flex;
  justify-content: center;
  align-items:center;
  background: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};
  order: 1;

  ${breakpoint.down('phone')`
    min-height: 100vw;
  `}

  ${breakpoint.up('tablet')`
    order: ${({ reverse }) => reverse ? '2' : '1'};
  `}

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => `${theme.blockpadding} ${theme.gutterLarge}`};
  `}
`

export const ImageItem = styled(GridItem)`
  position: relative;
  order: 2;
  height: 100vw;
  
  ${breakpoint.up('phone')`
    height: calc(100vw * 0.5625);
  `}

  ${breakpoint.up('desktop')`
    height: auto;
    order: ${({ reverse }) => reverse ? '1' : '2'};
  `}
`

export const ImageIndex = styled.div`
  position: absolute;
  bottom: 0;
  ${({ reverse }) => reverse && 'right: 0;'}
  ${({ reverse }) => reverse === false && 'left: 0;'}
  width: 3.89rem;
  height: 3.89rem;
  background-color: ${({ theme }) => theme.colorAccent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.11rem;
  font-weight: 500;
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28rem;
`

export const Title = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const BodyText = styled(ParagraphLarge)``

export const CTAButton = styled(CTA)`
  position: absolute;
  left: 50%;
  bottom: 100vw;
  transform: translate(-50%, 50%);
  background-color: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.colorWhite};
  z-index: ${({ theme }) => theme.zLevel1};

  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.phone}px) {
      bottom: calc(100vw * 0.5625);
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      bottom: 0;
    }
  `}


  ${({ ctaOnTop, theme }) => ctaOnTop && `
    top: unset;
    bottom: 100vw;
    transform: translate(-50%, 50%);

    @media (min-width: ${theme.breakpoints.phone}px) {
      bottom: calc(100vw * 0.5625);
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      bottom: unset;
      top: 0;    
      transform: translate(-50%, -50%);
    }
  `}

  svg {
    fill: ${({ theme }) => theme.colorWhite};
  }
`