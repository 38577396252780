import React from 'react'
import { graphql } from 'gatsby'
import ThreeDViewer from '../../ThreeDViewer'

const ThreeDTour = ({
  coverImage,
  thumbnail,
  instructions,
  linksToInteriors
}) => {
  return (
    <ThreeDViewer
      coverImage={coverImage}
      thumbnail={thumbnail}
      instructions={instructions}
      linksToInteriors={linksToInteriors}
    />
  )
}


export const THREED_TOUR_BLOCK_FRAGMENT = graphql`
  fragment ThreedTourBlockFragment on DatoCmsThreedTourBlock {
    id
    coverImage {
      fluid(maxWidth: 2560) {
        ...GatsbyDatoCmsFluid
      }
    }
    thumbnail {
      fixed(width: 180) {
        ...GatsbyDatoCmsFixed
      }
    }
    instructions
    interiorLinks
    linksToInteriors {
      url
      title
    }
  }  
`

export default ThreeDTour