import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  StyledVideo,
  PlayButton,
  PlayIcon
} from './styles'

const Video = ({
  url,
  caption,
  autoPlay,
  onPlayButtonClick,
  showPlayButton,
  className,
  playsInline
}) => {
  const videoNode = React.createRef()
  const [showControls, setShowControls] = useState(false)
  const [isPlaying, setIsPlaying] = useState(autoPlay)

  function onPlay() {
    videoNode.current.play()
    setShowControls(true)
    setIsPlaying(true)
  }

  return (
    <>
      {url && (
        <Wrapper className={className}>
          {(showPlayButton && !isPlaying) || (!autoPlay && showPlayButton) ? (
            <PlayButton
              onClick={() => {
                typeof onPlayButtonClick === 'function'
                  ? onPlayButtonClick()
                  : onPlay()
              }}
            >
              <PlayIcon />
            </PlayButton>
          )
            : null}
          <StyledVideo
            playsInline={playsInline}
            autoPlay={autoPlay}
            loop
            muted
            ref={videoNode}
            controls={showControls}
          >
            <source src={url} type="video/mp4" />
            <track label="Video label" kind={caption} />
          </StyledVideo>
        </Wrapper>
      )}
    </>
  )
}

Video.propTypes = {
  url: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  onPlayButtonClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ]),
  showButton: PropTypes.bool,
  playsInline: PropTypes.bool
}

Video.defaultProps = {
  url: '',
  caption: '',
  autoPlay: false,
  onPlayButtonClick: false,
  showPlayButton: false,
  playsInline: false
}

export default Video