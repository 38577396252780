
import styled from "styled-components";
import Button from "../Button";
import {
    breakpoint
  } from '../../layout/mixins'

export const IMG = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: ${({ theme }) => theme.gutter};
`;

export const CoverImage = styled.img`
  max-width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.gutter};
  margin-bottom: ${({ theme }) => theme.gutter};
`;

export const SmallImage = styled.img`
  max-width: calc(50% - ${({ theme }) => theme.gutter} / 2);
  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
`;

export const Cover = styled.div`
  overflow: hidden;
  position: relative;
  margin-top: ${({ theme }) => theme.gutter};

  ${({ height }) =>
    height &&
    `
    height: ${height}px;
  `}

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const Grid = styled.article`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
    ${breakpoint.down('tablet')`
        width: 100%;
    `}
    padding: 0 ${({ theme }) => theme.gutter};
    width: 75%;
    margin: 0 auto;
    padding-bottom: ${({ theme }) => `${theme.blockpaddingMobile}`};
    ${breakpoint.up('desktop')`
        padding-bottom: ${({ theme }) => `${theme.blockpadding}`};
    `}

    ${breakpoint.up('maxWrapper')`
        padding-bottom: ${({ theme }) => `${theme.blockpadding}`};
    `}
`