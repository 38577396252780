import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  breakpoint,
  Heading3,
  ParagraphLarge
} from '../../layout/mixins'
import Icon from '../../layout/icons/download.svg'
import CTA from '../CTA'
import Button from '../Button'
import ThreeDViewer from '../ThreeDViewer'
import Printer from '../../layout/icons/printer.svg'
import Facebook from '../../layout/icons/facebook.svg'
import Twitter from '../../layout/icons/twitter.svg'
import Mail from '../../layout/icons/mail.svg'

export const Section = styled.section`
  position: relative;
`

export const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`
export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
    height: auto;
    min-height: 40rem;
  `}
`

export const ImageItem = styled(GridItem)`
  position: relative;
  height: 100vw;
  
  ${breakpoint.up('phone')`
    height: calc(100vw * 0.5625);
  `}

  ${breakpoint.up('desktop')`
    height: auto;
    order: ${({ reverse }) => (reverse ? '1' : '2')};
  `}
`

export const ImageIndex = styled.div`
  position: absolute;
  bottom: 0;
  ${({ reverse }) => reverse && 'right: 0;'}
  ${({ reverse }) => reverse === false && 'left: 0;'}
  width: 3.89rem;
  height: 3.89rem;
  background-color: ${({ theme }) => theme.colorAccent};
  align-items: center;
  justify-content: center;
  font-size: 1.11rem;
  font-weight: 500;
  display: none;

  ${breakpoint.up('desktop')`
    display: flex;
  `}
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
 
`

export const TextItem = styled(GridItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};

  ${breakpoint.down('phone')`
    min-height: 100vw;
  `}

  ${breakpoint.up('tablet')`
    order: ${({ reverse }) => (reverse ? '2' : '1')};
  `}

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => `${theme.blockpadding} ${theme.gutterLarge}`};
  `}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28rem;
`

export const Title = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const BodyText = styled(ParagraphLarge)``

export const Description = styled(ParagraphLarge)``

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.gutterLarge};
  padding-bottom: ${({ theme }) => theme.gutterSmall};
  border-bottom: 1px solid ${({ theme }) => theme.colorBlack};
  justify-content: space-between;
`
export const Item = styled.li`
  padding-top: ${({ theme }) => theme.gutterSmall};
  padding-right: ${({ theme }) => theme.gutter};

  &:last-child {
    padding-right: 0;
  }
`

export const Prefix = styled.span`
  display: block;
  font-weight: 500;
  font-size:  ${({ theme }) => theme.fontsizeSmall};
`
export const Emphasis = styled.span`
  display: bock;
  font-weight: 500;
  line-height: 1.5;
`

export const CTAContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.gutterMedium};
  align-items: center;
  justify-content: center;

  ${breakpoint.up('desktop')`
    position: absolute;
    bottom: ${({ theme }) => theme.gutterMedium};

    > *:first-child {
      margin-right: ${({ theme }) => theme.gutter};
    }
  `}
`

export const TourButton = styled(Button)`
    position: absolute;
    left: 50%;
    bottom: ${({ theme }) => theme.gutterMedium};
    transform: translateX(-50%);
`

export const DownloadLink = styled.a`
  padding-top: ${({ theme }) => theme.gutterSmall};
  display: flex;
`

export const DownloadIcon = styled(Icon)`
  width: 1em;
  height: 1em;
  margin-right: ${({ theme }) => theme.gutterSmall};
`

export const DownloadText = styled.span``

export const UnderlineLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

export const StyledCTA = styled(CTA)`
  ${breakpoint.down('tablet')`
    min-width: 75%;
    justify-content: center;
    margin-top: 1rem;
  `}
`
export const StyledButton = styled(Button)`
  ${breakpoint.down('tablet')`
    min-width: 75%;
    justify-content: center;
    margin-top: 0;
  `}
`
export const StyledThreeDViewer = styled(ThreeDViewer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zLevel4};
`

export const Sharebar = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  height: ${({ theme }) => theme.gutter};
  display: flex;
`


export const IconLink = styled.a`
  display: block;
  width: ${({ theme }) => theme.gutter};
  height: ${({ theme }) => theme.gutter};
`

const IconCSS = css`
  width: 1rem;
  height: 1rem;
`
export const PrintIcon = styled(Printer)`${IconCSS}`
export const FacebookIcon = styled(Facebook)`${IconCSS}`
export const TwitterIcon = styled(Twitter)`${IconCSS}`
export const MailIcon = styled(Mail)`${IconCSS}`


export const AltImagesShortLink = styled.div`
  width: 100%;
  display: none;
  margin-bottom: ${({ theme }) => theme.gutter};

  * {
    width: 100%
  }

  ${breakpoint.down('tablet')`
      display: block;
  `}
`;

export const ImagesShortLink = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  z-index: 1;
  ${breakpoint.down('tablet')`
      display: none;
  `}
`;