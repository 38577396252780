import styled from 'styled-components'
import siteTheme from '../../layout/theme'
import { breakpoint } from '../../layout/mixins'

export const InputGroup = styled.div`
  position: relative;
  margin-bottom: ${siteTheme.gutter};
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.textColor};
  }
`

export const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  width: 100%;
  font-size: 1.11em;
  line-height: 1.45em;
  padding: 0.5rem 0;
  outline: none;
  color: ${({ theme }) => theme.textColor};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const TextAreaGroup = styled.div`
  position: relative;
  margin-bottom: ${siteTheme.gutter};

  ${breakpoint.up('desktop')`
    height: 5rem;
  `}
`

export const StyledTextArea = styled.textarea`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  width: calc(100% - 1px);
  height: 2em;
  font-size: 1.11em;
  line-height: 1.45em;
  outline: none;
  color: ${({ theme }) => theme.textColor};
  border: 1px solid ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  resize: none;
  padding: 0.3em 0.5em;

  &::placeholder {
    font-family: ${({ theme }) => theme.fontfamilyPrimary};
    color: ${({ theme }) => theme.textColor};
  }

  &:focus {
    height: 10rem;
    border: 1px solid ${({ theme }) => theme.textColor};
    // transform: translateY(2em); 
    z-index: 99;
  }

  &:focus::placeholder {
    color: red;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  ${breakpoint.up('desktop')`
    position: absolute;
    top: 2rem;
  `}
`

export const Label = styled.label`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
  transform-origin: 0% 0%;
  font-size: 1.11em;
  line-height: 1.45em;
  color: ${({ theme }) => theme.textColor};
  pointer-events: none;

  ${({ isFocused }) => isFocused && `
    transform: translateY(-150%) scale(0.7);
  `}

  ${TextAreaGroup} & {
    position: static;
    display: block;
    margin-bottom: 0.5rem;
    transform: none;
    pointer-events: all;

    // position: absolute;
    top: 0.5rem;
  }
`

export const StyledMessage = styled.span`
  position: absolute;
  right: 0;
  bottom: 0.5rem;
  font-size: 14px;

  ${({ theme, type }) => type === 'error' && `
    color: ${theme.colorAccent};
  `}

  ${({ theme, type }) => type === 'success' && `
    color: ${theme.colorAccent};
  `}
`

export const themes = {
  light: {
    textColor: siteTheme.colorBlack,
    backgroundColor: siteTheme.colorWhite
  },
  dark: {
    textColor: siteTheme.colorWhite,
    backgroundColor: siteTheme.colorBlack
  }
}