import React, { useEffect } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { 
  AUTOCOMPLETE_LOADING_TEXT,
  AUTOCOMPLETE_ERROR_MESSAGE
} from '../../content'
import {
  Label,
  StyledInput,
} from '../Input/styles'
import { 
  StyledInputGroup,
  DropdownContainer,
  DropdownItem,
  LoadingItem,
  ErrorItem
} from './styles'

class DirectionInputAutocomplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      address: '',
      isFocused: false,
      showDropdown: true,
      error: false
    }
  }

  handleChange = address => {
    this.setState({ 
      address,
      error: false 
    })
  }

  handleBlur = (e) => {
    this.setState({ showDropdown: false })

    if (e.target.value === '') {
      this.setState({ isFocused: false })
    }
  }

  handleFocus = (e) => {
    this.setState({ 
      isFocused: true, 
      showDropdown: true 
    })
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.handleResponse(latLng)
      })
      .catch(error => console.error('Error', error))
  }

  handleError = (status, clearSuggestions) => {
    clearSuggestions()
    this.setState({ error: true })
  }  

  render() {
    const { error } = this.state
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
        onError={this.handleError}
        searchOptions={{
          componentRestrictions: {
            country: 'se'
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <StyledInputGroup>
              <Label isFocused={this.state.isFocused}>{this.props.label}</Label>
              <StyledInput
                {...getInputProps()}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
            </StyledInputGroup>
            {this.state.showDropdown && (
              <DropdownContainer>
                {loading && <LoadingItem>{AUTOCOMPLETE_LOADING_TEXT}</LoadingItem>}
                {error && (getInputProps().value != '') ? <ErrorItem>{AUTOCOMPLETE_ERROR_MESSAGE}</ErrorItem> : null}
                {suggestions.map(suggestion => {
                  return (
                    <DropdownItem
                      {...getSuggestionItemProps(suggestion)}
                      active={suggestion.active}
                    >
                      <span>{suggestion.description}</span>
                    </DropdownItem>
                  )
                })}
              </DropdownContainer>
            )}
          </>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default DirectionInputAutocomplete