import { trimUnits } from '../utils/helpers'

class Theme {
  constructor() {
    this.colorAccent = '#FFE9E6'
    this.colorBlack = '#000'
    this.colorWhite = '#FFF'
    this.colorGrey = '#F0F0F0'
    this.colorGreylight = '#FAFAFA'
    this.colorDanger = 'red'

    this.textcolorPrimary = '#000'
    this.textcolorSecondary = '#fff'

    this.colorHr = '#CCCCCC'

    this.gridUnit = '5.5rem'
    this.gutter = '1.5rem'
    this.gutterSmall = `${trimUnits(this.gutter) / 2}rem`
    this.gutterMedium = `${trimUnits(this.gutter) * 1.5}rem`
    this.gutterLarge = `${trimUnits(this.gutter) * 2}rem`
    this.gutterXLarge = `${trimUnits(this.gutter) * 3}rem`
    this.gutterXXLarge = `${trimUnits(this.gutter) * 4}rem`

    this.buttonIconSize = '1.2em'

    this.portraitImageSize = '7em'

    this.maxWidth = '1440px'
    this.maxWidthWrapper = '1800px'

    this.headerheightMobile = this.gutterLarge
    this.headerheight = '4rem'

    this.blockpaddingMobile = this.gutterXLarge
    this.blockpadding = `${trimUnits(this.gutterXXLarge) * 1.5}rem`

    this.fontfamilyPrimary = 'futura-pt, sans-serif'
    this.fontfamilySecondary = 'Jigsaw Stencil Light'

    this.fontsizeMobile = '18px'
    this.fontsizeResponsive = '18px'
    this.fontsizeRoot = '18px'
    this.fontsizeSmall = '0.8em'
    this.fontsizeMedium = '1.67em'

    this.lineheightRegular = '1.4em'
    this.lineheightSmall = '1.1em'
    this.lineHeightMedium = '1.5em'

    this.buttonRadius = '40px'
    this.contentRadius = '8px'

    this.boxShadow = '0px 6px 10px rgba(0, 0, 0, 0.1)'

    this.zLevel1 = '1'
    this.zLevel2 = '2'
    this.zLevel3 = '3'
    this.zLevel4 = '4'
    this.zLevel5 = '5'

    this.transitionRegular = '0.2s ease-out'

    this.breakpoints = {
      min: 0,
      phone: 668,
      tablet: 768,
      desktop: 1024,
      large: 1280,
      max: parseInt(trimUnits(this.maxWidth), 10),
      maxWrapper: parseInt(trimUnits(this.maxWidthWrapper), 10)
    }
  }
}

const theme = new Theme()

export default theme