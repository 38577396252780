import React from 'react'
import PropTypes from 'prop-types'
import { getIframeSrc } from '../../utils/helpers'
import {
  Wrapper,
  Inner,
  Grid,
  Usp1,
  Usp2,
  Usp3,
  ImageItem,
  Image,
  VideoItem,
  Iframe,
  Arrow,
  ArrowSVG
} from './styles'
import Video from '../Video'
import Toggle from '../Toggle'
import Modal from '../Modal'

const Mosaic = ({
  usp1,
  usp2,
  usp3,
  previewVideo,
  fullVideoEmbedded,
  image,
  ctaUrl
}) => (
  <Wrapper>
    <Inner>
      <Grid>
        <Usp1 dangerouslySetInnerHTML={{ __html: usp1 }} />
        <VideoItem>
          <Toggle>
            {(on, toggle) => (
              <>
                <Video
                  url={previewVideo.url}
                  caption="Hitta hit"
                  autoPlay
                  showPlayButton
                  onPlayButtonClick={toggle}
                  playsInline
                />
                {on && (
                  <Modal toggle={toggle}>
                    <Iframe
                      src={getIframeSrc(fullVideoEmbedded.providerUid)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      autoPlay="true"
                    />
                  </Modal>
                )}
              </>
            )}
          </Toggle>
        </VideoItem>
        <ImageItem>
          <Image fluid={image.fluid} />
        </ImageItem>
        <Usp2 dangerouslySetInnerHTML={{ __html: usp2 }} />
        <Usp3 dangerouslySetInnerHTML={{ __html: usp3 }} />
        <Arrow to={ctaUrl}>
          <ArrowSVG />
        </Arrow>
      </Grid>
    </Inner>
  </Wrapper>
)

Mosaic.propTypes = {
  usp1: PropTypes.string,
  usp2: PropTypes.string,
  usp3: PropTypes.string,
  previewVideoEmbedded: PropTypes.shape({
    url: PropTypes.string
  }),
  fullVideo: PropTypes.shape({
    url: PropTypes.string
  }),
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  ctaUrl: PropTypes.string
}

Mosaic.defaultProps = {
  usp1: '',
  usp2: '',
  usp3: '',
  previewVideo: {
    url: ''
  },
  fullVideoEmbedded: {
    url: ''
  },
  image: {
    fluid: {}
  },
  ctaUrl: ''
}

export default Mosaic