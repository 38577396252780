import React from 'react'
import {
  Container, Heading, List, Item, Link, Icon
} from './styles'

const FacilityLinks = ({ links }) => (
  <Container>
    <Heading>Länkar</Heading>
    <List>
      {links.map((link) => (
        <Item key={link.url}>
          <Link rel="nofollow noopener noreferrer" title={link.name} target="_blank" href={link.url}>{link.name}</Link>
          <Icon />
        </Item>
      ))}
    </List>
  </Container>
)

export default FacilityLinks