import React from 'react'
import { NavList, NavListItem } from './styles'

const BlockMenu = ({ className }) => (
  <nav className={className}>
    <NavList>
      <NavListItem>Avståndsberäknaren</NavListItem>
    </NavList>
  </nav>
)

export default BlockMenu