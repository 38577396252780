exports.onRouteUpdate = ({ location }) => {
  checkHash(location)
}

const checkHash = location => {
  let { hash } = location

  if (hash) {
    const element = document.querySelector(hash)
    if (!element) return

    setTimeout(() => {
      const elementTop = element.getBoundingClientRect().top
      const bodyTop = document.body.getBoundingClientRect().top
      window.scroll({
        top: elementTop - bodyTop,
        left: 0,
        behavior: 'smooth'
      })
    }, 500)
  }
}