import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'

export const Wrapper = styled.div`
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 24px;

    ${breakpoint.up('tablet')`
        padding: 100px 56px;
    `}
    
    ${breakpoint.up('desktop')`
        padding: 100px 80px;
    `}
`

export const Heading = styled.h1`
    font-size: 42px;
    text-align: center;

    ${breakpoint.up('phone')`
        font-size: 52px;
    `}
    
    ${breakpoint.up('tablet')`
        font-size: 64px;
    `}
`

export const Paragraph = styled.p`
    margin-top: 64px;
    margin-bottom: 64px;
    text-align: center;

    ${breakpoint.up('phone')`
        max-width: 80%;
    `}
    
    ${breakpoint.up('tablet')`
        max-width: 70%;
    `}
   
   ${breakpoint.up('desktop')`
        max-width: 50%;
    `}
`