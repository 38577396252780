import styled from 'styled-components'
import {
  breakpoint,
  Heading3,
  ParagraphLargeStyles
} from '../../layout/mixins'
import Img from 'gatsby-background-image'
import CTA from '../../components/CTA'

export const Section = styled.section``

export const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
    height: auto;
    min-height: 40rem;
  `}
`

export const TextItem = styled(GridItem)`
  display: flex;
  align-items: flex-end;
  background: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.gutter}`};
  order: 1;

  ${breakpoint.down('phone')`
    min-height: 100vw;
  `}

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => `${theme.gutterXXLarge}`};
    order: 2;
  `}
`

export const ImageItem = styled(GridItem)`
  position: relative;
  height: 100vw;
  order: 2;
  
  ${breakpoint.up('phone')`
    height: calc(100vw * 0.5625);
  `}

  ${breakpoint.up('desktop')`
    order: 1;
    height: auto;
  `}
`

export const Image = styled(Img)`
  height: 100%;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  & .rte p {
    ${ParagraphLargeStyles}
  }
`

export const Title = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const ContactPerson = styled.div`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Locations = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('phone')`
    flex-direction: row;
  `}

  ${breakpoint.up('desktop')`
    flex-direction: column;
  `}

  ${breakpoint.up('max')`
    flex-direction: row;
  `}
`

export const Location1 = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    margin-right: ${({ theme }) => theme.gutterXXLarge};
  `}

  ${breakpoint.up('desktop')`
    margin-right: 0;
  `}

  ${breakpoint.up('max')`
    margin-right: ${({ theme }) => theme.gutterXXLarge};
  `}
`

export const Location2 = styled.div``

export const StyledCTA = styled(CTA)`
  position: absolute;
  left: 50%;
  bottom: 100vw;
  transform: translate(-50%, 50%);
  background-color: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.colorWhite};
  z-index: ${({ theme }) => theme.zLevel1};

  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.phone}px) {
      bottom: calc(100vw * 0.5625);
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      bottom: 0;
    }
  `}

  ${({ ctaOnTop, theme }) => ctaOnTop && `
    top: unset;
    bottom: 100vw;
    transform: translate(-50%, 50%);

    @media (min-width: ${theme.breakpoints.phone}px) {
      bottom: calc(100vw * 0.5625);
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      bottom: unset;
      top: 0;    
      transform: translate(-50%, -50%);
    }
  `}

  svg {
    fill: ${({ theme }) => theme.colorWhite};
  }
`