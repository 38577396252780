import styled from 'styled-components'
import siteTheme from '../../layout/theme'

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 1.1rem ${siteTheme.gutter};
  outline: none;
  border-radius: ${siteTheme.buttonRadius};
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: pointer;
  
  &:focus {
    outline: none;
  }
`

export const ButtonText = styled.span`
  font-family: ${siteTheme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  white-space: nowrap;
`

export const themes = {
  cta: {
    backgroundColor: siteTheme.colorAccent,
    textColor: siteTheme.textcolorPrimary
  },
  dark: {
    backgroundColor: siteTheme.colorBlack,
    textColor: siteTheme.textcolorSecondary
  }
}