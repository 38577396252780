import styled from 'styled-components'
import BlockMenu from '../../BlockMenu'

export const Section = styled.section`
  position: relative;
`

export const StyledBlockMenu = styled(BlockMenu)`
  position: absolute;
  top: ${({ theme }) => theme.gutterSmall};
  left: ${({ theme }) => theme.gutterLarge};

`