import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/dotted-arrow.svg'
import CloseIcon from '../../layout/icons/close.svg'
import CustomLink from '../CustomLink'

export const Nav = styled.nav`
  display: none;
  height: 100%;

  ${breakpoint.up('desktop')`
      display: block;
  `}
`

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`

export const NavItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1.22rem;
  line-height: 1.1em;
  margin-right: ${({ theme }) => theme.gutter};
  cursor: pointer;

  &:last-child {
    margin-right: 0;
    border-right: 2px solid black;
  }

  && a {
    color: ${({ theme }) => theme.textcolorPrimary};
  }
`

export const InquiryContainer = styled.li`
  position: relative;
  width: 14rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.gutter}`};
  cursor: pointer;

  .inquiry-open & {
    background-color: ${({ theme }) => theme.textcolorPrimary};
  }

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 2px;
    height: 1em;
    background: ${({ theme }) => theme.colorBlack};
  }
`

export const InquiryLink = styled(CustomLink)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inquiry-open & {
    pointer-events: none;
  }
`

export const InquiryItem = styled(NavItem)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  fill: ${({ theme }) => theme.textcolorPrimary};
  margin-right: 0;
  pointer-events: none;

  .inquiry-open & {
    display: none;
  }
`

export const CloseItem = styled(NavItem)`
  stroke: ${({ theme }) => theme.textcolorPrimary};
  display: none;
  pointer-events: none;

  .inquiry-open & {
    display: block;
    color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${({ theme }) => theme.textcolorPrimary};
  witdh: 1em;
  height: 1em;

  .inquiry-open & {
    display: none;
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  width: 1.11rem;
  height: 1.11rem;
  stroke: ${({ theme }) => theme.textcolorSecondary};
  display: none;
  pointer-events: none;

  .inquiry-open & {
    display: block;
  }
`