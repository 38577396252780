import React from 'react'
import {
  Container, Heading, List, Item, Link, Icon
} from './styles'

const FacilityDocuments = ({ files }) => (
  <Container>
    <Heading>Dokument</Heading>
    <List>
      {files.map((file) => (
        <Item key={file.url}>
          <Link target="_blank" download="test.pdf" title={file.name} href={file.url}>{file.name}</Link>
          <Icon />
        </Item>
      ))}
    </List>
  </Container>
)

export default FacilityDocuments