import styled, { css } from 'styled-components'
import Img from 'gatsby-background-image'
import {
  breakpoint, Heading3Styles, ParagraphStyles, ParagraphLargeStyles
} from '../../layout/mixins'
import DottedArrow from '../../layout/icons/dotted-arrow.svg'

export const Arrow = styled(DottedArrow)`
  width: ${({ theme }) => theme.gutterMedium};
  height: ${({ theme }) => theme.gutterMedium};
  margin-left: ${({ theme }) => theme.gutterLarge};
`

export const Description = styled.div`
  padding: ${({ theme }) => theme.gutter};
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  transition: opacity ${({ theme }) => theme.transitionRegular};
`
export const Header = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.gutter};
  transition: background ${({ theme }) => theme.transitionRegular};
`
export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: ${({ theme }) => theme.fontsizeMedium};
  color: ${({ theme }) => theme.textcolorSecondary};
  line-height: 1;
  font-weight: 500;
  text-transform: initial;
  transition: color ${({ theme }) => theme.transitionRegular};
`

export const H3 = styled.h3`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorSecondary};
  transition: color ${({ theme }) => theme.transitionRegular};
  margin-top: ${({ theme }) => theme.gutterSmall};
`

const ContainerHover = css`
  ${breakpoint.up('phone')`
    &:hover {
      ${Header} {
        background: rgba(255, 233, 230, 0.9);
        color: ${({ theme }) => theme.colorBlack} !important;
      }

      ${H2}, ${H3} {
        color: ${({ theme }) => theme.colorBlack};
      }

      ${Description} {
        opacity: 1;
      }
    }
  `}
`

export const Container = styled(Img)`
  width: 100%;
  position: relative;
  padding-bottom: 100%;

  ${({ noHover, theme }) => (noHover ? `
    ${H2}, ${H3} {
      max-width: 50%;
    }
  ` : ContainerHover)}
`


export const Footer = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction column;
`
export const Content = styled.div`
  ${({ noBackground, theme }) => (noBackground ? `
    color: ${theme.colorWhite};
    padding: ${theme.gutter};
  ` : `
    color: ${theme.colorBlack};
    background: ${theme.colorWhite};
  `)};
`


export const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const Item = styled.li`
  padding-top: ${({ theme }) => theme.gutterSmall};
  padding-right: ${({ theme }) => theme.gutter};
`

export const Prefix = styled.span`
  display: block;
  font-weight: 500;
  font-size:  ${({ theme }) => theme.fontsizeSmall};
`
export const Emphasis = styled.span`
  display: bock;
  font-weight: 500;
  line-height: 1.5;
`
