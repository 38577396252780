import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Wrapper,
  Bus,
  BusIcon,
  Boat,
  BoatIcon,
  Car,
  CarIcon,
  Info,
  Header,
  Title
} from './styles'

const Footer = () => {
  const { datoCmsFooter: {
    busTitle,
    busInfo,
    boatTitle,
    boatInfo,
    carTitle,
    carInfo
  }} = useStaticQuery(footerQuery)

  return (
    <Wrapper className="footer">
      <Bus>
        <Header>
          <BusIcon />
        </Header>
        <Title>{busTitle}</Title>
        <Info className="rte" dangerouslySetInnerHTML={{ __html: busInfo }} />
      </Bus>
      <Boat>
        <Header>
          <BoatIcon />
        </Header>
        <Title>{boatTitle}</Title>
        <Info className="rte" dangerouslySetInnerHTML={{ __html: boatInfo }} />
      </Boat>
      <Car>
        <Header>
          <CarIcon />
        </Header>
        <Title>{carTitle}</Title>
        <Info className="rte" dangerouslySetInnerHTML={{ __html: carInfo }} />
      </Car>
    </Wrapper>
  )
}

Footer.propTypes = {
  info: PropTypes.node
}

Footer.defaultProps = {
  info: '<div>Hello World</div>'
}

export default Footer

export const footerQuery = graphql`
  query FooterQuery {
    datoCmsFooter {
      boatTitle
      boatInfo
      busTitle
      busInfo
      carTitle
      carInfo
    }
  }
`
