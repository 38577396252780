import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  breakpoint,
  Heading2Styles
} from '../../layout/mixins'
import Img from 'gatsby-image'
import arrow from '../../layout/icons/usp-arrow.svg'
import theme from '../../layout/theme'

export const Wrapper = styled.div`
  position: relative;
`

export const Inner = styled.div`
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
`

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-gap: ${({ theme }) => `calc(${theme.gutterSmall}/2)`};

  ${breakpoint.up('desktop')`
    grid-gap: ${theme.gutterSmall};
  `}
`

export const Usp = styled.div`
  background-color: ${({ theme }) => theme.colorAccent};
  display: flex;
  justify-content: center;
  align-items: center;




  p {
    font-family: ${theme.fontfamilySecondary};
    font-size: 1.67rem;
    color: ${theme.textcolorPrimary};
    text-align: center;

    ${breakpoint.up('max')`
      ${Heading2Styles}
      text-transform: none;
    `}
  }
`

export const Usp1 = styled(Usp)`
  grid-column: 1 / span 2;
`

export const Usp2 = styled(Usp)`
  grid-column 2 / span 2;
`

export const Usp3 = styled(Usp)`
  p {
    ${breakpoint.down('phone')`
      font-size: 1.2rem;
    `}
  }
`

export const ImageItem = styled.div`
  grid-row: 2 / span 2;
`

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

export const VideoItem = styled.div``

export const Arrow = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colorBlack};
`

export const ArrowSVG = styled(arrow)`
  width: 51.32%;
  height: 55.7%;
  fill: ${theme.colorWhite};
`

export const Iframe = styled.iframe`
  width: calc(100vw - (2 * ${theme.gutter}));
  height: calc(100vw - (2 * ${theme.gutter}));

  ${breakpoint.up('tablet')`
    width: 60vw;
    height: calc(60vw * 0.5625);
  `}
`
