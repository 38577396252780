import styled from 'styled-components'
import DottedArrow from '../../layout/icons/dotted-arrow.svg'
import { breakpoint } from '../../layout/mixins'


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.gutter};
  padding: ${({ theme }) => theme.gutter};
  border: 1px solid ${({ theme }) => theme.colorBlack};
  border-radius: ${({ theme }) => theme.contentRadius};

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => theme.gutterMedium};
  `}
`

export const Button = styled.button`
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;
  `}  
`

export const Details = styled.article`
  width: 100%;
  max-width: 30em;
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontsizeSmall};

  ${breakpoint.up('tablet')`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`


export const Arrow = styled(DottedArrow)`
  width: 1em;
  height: 1em;
  flex: 0 0 1em;
  margin-right: ${({ theme }) => theme.gutterSmall};
  transform: rotate(-180deg);
`

export const IconOptions = styled.article`
  font-size: 12px;
  display: flex;
  flex-direction: row;

  ${breakpoint.up('tablet')`
    margin-left:  ${({ theme }) => theme.gutter};
  `}    

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.gutterSmall};
  }
`

export const Route = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const FromAddress = styled.span`
  font-size: 1rem;
  line-height: 1.3;
`

export const ToAddress = styled.span`
  font-size: 1rem;
  line-height: 1.3;
`

export const DirectionArrow = styled.span`
  font-size: 1em;
`

export const TimeToDestination = styled.div`
  display: inline-block;
  font-size: 2.11rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: ${({ theme }) => theme.gutterSmall};
  background: ${({ theme }) => theme.colorAccent};
  padding: 0 0.5rem;
`

export const Distance = styled.span`
  font-size: 1.33rem;
  font-weight: 500;
  line-height: 1.3;
`

export const DirectionsLink = styled.a`
  font-size: 0.89rem;
  line-height: 1.3;
`