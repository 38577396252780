import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyledSlider, themes } from './styles'

class SimpleSlider extends Component {
  render() {
    const { autoPlay, arrowStyle } = this.props
    const settings = {
      infinite: true,
      speed: 500,
      autoplaySpeed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => this.props.onSlideChange(index),
      autoplay: autoPlay
    }
    return (
      <StyledSlider
        arrowStyle={arrowStyle}
        className={this.props.className}
        {...settings}
      >
        {this.props.children}
      </StyledSlider>
    )
  }
}

export default SimpleSlider

SimpleSlider.propTypes = {
  autoPlay: PropTypes.bool,
  arrowStyle: PropTypes.string
}

SimpleSlider.defaultProps = {
  autoPlay: false,
  arrowStyle: 'default'
}