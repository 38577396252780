import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Halign } from '../../../layout/mixins'
import {
  Section,
  Grid,
  TextItem,
  ImageItem,
  Image,
  TextContent,
  Title,
  BodyText,
  Arrow
} from './styles'
import CustomLink from '../../CustomLink'


const ReportageHightlight = ({
  title,
  content,
  image,
  reverse,
  linkUrl
}) => (
  <Section>
    <Halign>
      <Grid>
        <TextItem reverse={reverse}>
          <TextContent>
            <Title as="h2">{title}</Title>
            <BodyText className="rte" dangerouslySetInnerHTML={{ __html: content }} />
          </TextContent>
        </TextItem>
        <ImageItem reverse={reverse}>
          <CustomLink to={linkUrl}>
            <Image fluid={image.fluid} />
            <Arrow />
          </CustomLink>
        </ImageItem>
      </Grid>
    </Halign>
  </Section>
)

ReportageHightlight.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  reverse: PropTypes.bool,
  linkUrl: PropTypes.string
}

ReportageHightlight.defaultProps = {
  title: '',
  content: '',
  image: {
    fluid: {}
  },
  reverse: false,
  linkUrl: ''
}

export default ReportageHightlight

export const REPORTAGE_HIGHLIGHT_BLOCK_FRAGMENT = graphql`
  fragment ReportageHighlightBlockFragment on DatoCmsReportageHighlightBlock {
    reportage {
      title
      content
      image {
        fluid(maxWidth: 1280) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    reverse
    linkUrl
  }
`