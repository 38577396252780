import React from 'react'
import { Grid, GridItem } from './styles'
import FacilityLinks from '../FacilityLinks'
import FacilityDocuments from '../FacilityDocuments'
import FacilityContact from '../FacilityContact'
import FacilityMatchmaker from '../FacilityMatchmaker'

const FacilityFooter = ({ links, files, administrator }) => (
  <Grid>
    <GridItem>
      <FacilityLinks links={links} />
    </GridItem>
    <GridItem>
      <FacilityDocuments files={files} />
    </GridItem>
    <GridItem>
      <FacilityContact administrator={administrator} />
    </GridItem>
    <GridItem>
      <FacilityMatchmaker />
    </GridItem>
  </Grid>
)

export default FacilityFooter