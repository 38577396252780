export const getIcon = icon => {
    switch (icon) {
        case "_external-link":
            return import("/src/layout/icons/_external-link.svg")
        case "bike":
            return import("/src/layout/icons/bike.svg")
        case "boat":
            return import("/src/layout/icons/boat.svg")
        case "bus":
            return import("/src/layout/icons/bus.svg")
        case "car":
            return import("/src/layout/icons/car.svg")
        case "check":
            return import("/src/layout/icons/check.svg")
        case "close":
            return import("/src/layout/icons/close.svg")
        case "dotted-arrow":
            return import("/src/layout/icons/dotted-arrow.svg")
        case "download":
            return import("/src/layout/icons/download.svg")
        case "external-link":
            return import("/src/layout/icons/external-link.svg")
        case "facebook":
            return import("/src/layout/icons/facebook.svg")
        case "hamburger":
            return import("/src/layout/icons/hamburger.svg")
        case "mail":
            return import("/src/layout/icons/mail.svg")
        case "marker":
            return import("/src/layout/icons/marker.svg")
        case "play-button":
            return import("/src/layout/icons/play-button.svg")
        case "printer":
            return import("/src/layout/icons/printer.svg")
        case "shoe":
            return import("/src/layout/icons/shoe.svg")
        case "slider-arrow":
            return import("/src/layout/icons/slider-arrow.png")
        case "spinner":
            return import("/src/layout/icons/spinner.svg")
        case "switch":
            return import("/src/layout/icons/switch.svg")
        case "train":
            return import("/src/layout/icons/train.svg")
        case "twitter":
            return import("/src/layout/icons/twitter.svg")
        case "usp-arrow":
            return import("/src/layout/icons/usp-arrow.svg")

        // Default
        default:
            return import("/src/layout/icons/usp-arrow.svg")
    }
  }
  