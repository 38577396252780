import React, { useState, useEffect, useContext } from 'react'
import { request } from 'graphql-request'
import { Router } from '@reach/router'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import { VITEC_GQL } from '../../config'
import FacilityHero from '../../components/FacilityHero'
import FacilityFacts from '../../components/FacilityFacts'
import FacilityDescriptions from '../../components/FacilityDescriptions'
import FacilityFooter from '../../components/FacilityFooter'
import {
  Warning, Grid, GridItem, StyledSpinner
} from './styles'
import DirectionsMap from '../../components/DirectionsMap'
import ErrorMessage from '../../components/ErrorMessage'
import FacilityInquiry from '../../components/FacilityInquiry'
import ImageGallery from '../../components/ImageGallery'
import FacilityAmenities from '../../components/FacilityAmenities'
import SEO from '../../components/SEO'
import { useScrollIntoView } from "@mantine/hooks";

const ItemComp = ({ guid }) => {
  const defaultSEOTags = [
    {
      tagName: 'title',
      content: 'Det vita huset - Objektbeskrivning'
    }
  ]

  const [facility, setFacility] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [seoTags, setSeoTags] = useState(defaultSEOTags)
  const isSSR = typeof window === 'undefined'
  const { scrollIntoView, targetRef } = useScrollIntoView();
  const [recipient, setRecipient] = useState(false);

  const facilityQuery = /* GraphQL */ `
    query getFacility($GUID: String!) {
      facility(GUID: $GUID) {
        guid
        objectType
        area
        yearlySqmRent
        earliastAdmittance
        admittanceDate
        facilityNumber
        files {
          name
          category
          url
        }
        descriptions {
          general
        }
        links {
          name
          category
          url
        }
        images {
          order
          category
          thumbnail {
            data
            aspectRatio
          }
          sizes {
            url
            name
            width
          }
        }
        primaryAdministrator {
          name
          title
          mobile
          email
          sizes {
            name
            url
            width
          }
          thumbnail {
            data
            aspectRatio
          }
        }
        settings {
          showRent
        }
        areaRent {
          yearlySqmRent
          heatIncluded
          yearlySqmHeatRent
          electricityIncluded
          yearlySqmElectricityRent
          waterIncluded
          yearlySqmWaterRent
          facilityTaxesIncluded
          yearlyFacilityTaxes
          coolingIncluded
          yearlySqmRentCooling
          customRent {
            text
            included
            yearlySqmRent
          }	
        }
        USP {
          headline
          shortDescription
          longDescription
        }
        descriptions {
          general
          architect
          history
          transportation
          service
          misc
          environment
          floorPlanning
          parking
        }
        posterImage {
          sizes {
            url
            name
          }
        }
        facilityType {
          egenskaper
        }
        certification
      }
    }
  `
  const facilityVariables = {
    GUID: guid
  }

  // Fetch our facility information
  useEffect(() => {
    const fetchObject = async () => {
      setIsLoading(true)

      try {
        const response = await request(VITEC_GQL, facilityQuery, facilityVariables)

        if (response?.facility?.posterImage?.sizes.length === 0) {
          throw new Error('No posterimage found')
        }

        setFacility(response.facility)
        setIsLoading(false)
      } catch (error) {
        setIsFailed(true)
        setIsLoading(false)
      }
    }

    fetchObject()
  }, [])

  // Update SEO meta tags when the facility is fetched
  useEffect(() => {
    if (!facility) {
      return
    }
    const posterImage = facility.posterImage.sizes.filter((size) => size.name && size.name == 'small')
    const OGImage = posterImage && posterImage[0] ? posterImage[0].url : null
    const title = `Det vita huset - ${facility.objectType}, ${facility.area}kvm`
    const desc = `${facility.descriptions.general}`

    const updatedTags = [
      {
        tagName: 'title',
        content: title
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:title',
          content: title
        }
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'description',
          content: desc
        }
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:description',
          content: desc
        }
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image',
          content: OGImage
        }
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:image',
          content: OGImage
        }
      }
    ]
    setSeoTags(updatedTags)
  }, [facility])

  // Add full height for prettier error messages
  useEffect(() => {
    document.body.classList.add('full-height')
    return function cleanup() {
      document.body.classList.remove('full-height')
    }
  }, [])

  useEffect(() => {
    if (facility.primaryAdministrator && facility.primaryAdministrator.email) {
      setRecipient(facility.primaryAdministrator.email);
    } else {
      setRecipient("uthyrning@novier.se");
    }
  }, [facility.primaryAdministrator]);


  // Unable to fetch an object
  function Error() {
    if (!isFailed) {
      return null
    }

    return (
      <Warning
        text="Oj då! Något gick fel från vårt håll. Försök igen senare."
      />
    )
  }

  // Object not found -> deleted or wrong ID?
  function NotFound() {
    if (isFailed) {
      return null
    }

    // Build time message, for SEO
    if (isSSR) {
      return (
        <ErrorMessage>
          <h3>Lokalbeskrivning från Det Vita Huset</h3>
          <StyledSpinner />
        </ErrorMessage>
      )
    }

    // Real message for client time
    return (
      <ErrorMessage>
        <h3>Lokalen hittades inte.</h3>
        <p>
          Vi kunde inte hitta lokalen du letade efter, den är antingen uthyrd eller så länkningen till lokalen fel.
          {' '}
          <Link to="/lediga-lokaler">Kolla våra lediga lokaler här.</Link>
        </p>
      </ErrorMessage>
    )
  }

  const amenities = facility?.facilityType?.egenskaper;


  // Dynamically set our components since they are using run time data
  const renderComponents = () => (
    facility ? (
      <>
        <SEO seoTags={seoTags} />
        <FacilityHero
          guid={facility.guid}
          images={facility.images}
          objectType={facility.objectType}
          area={facility.area}
          yearlySqmRent={facility.yearlySqmRent}
          descriptions={facility.descriptions}
          earliastAdmittance={facility.earliastAdmittance}
          admittanceDate={facility.admittanceDate}
          links={facility.links}
          files={facility.files}
          settings={facility.settings}
          USP={facility.USP}
          scrollToImages={scrollIntoView}
        />
        <Grid>
          <GridItem>
            <FacilityFacts
              objectType={facility.objectType}
              area={facility.area}
              earliastAdmittance={facility.earliastAdmittance}
              areaRent={facility.areaRent}
              admittanceDate={facility.admittanceDate}
              settings={facility.settings}
              certification={facility.certification}
            />
          </GridItem>
          <GridItem>
            <FacilityDescriptions
              facilityDesc={facility.USP}
              enviromentDesc={facility.descriptions}
            />
          </GridItem>
        </Grid>
        {amenities[0] !== null && amenities.length > 0 && (
          <Grid>
            <FacilityAmenities amenities={amenities} />
          </Grid>
        )}
        {facility.images.length > 0 && (
          <ImageGallery ref={targetRef} images={facility.images} />
        )}
        <FacilityFooter
          links={facility.links}
          files={facility.files}
          administrator={facility.primaryAdministrator}
        />
        <DirectionsMap />
        <FacilityInquiry
          recipient={recipient}
          additionalMessage={`Jag är intresserad av ${facility.objectType}, ${facility.area}kvm (${facility.facilityNumber}) i Det Vita Huset.`}
        />
      </>
    ) : (
      <NotFound />
    )
  )

  return (
    <>
      <Error />
      {isLoading ? (
        <StyledSpinner />
      ) : (
        renderComponents()
      )}
    </>
  )
}

const SingleFacility = () => (
  <Layout
    disableFooter
  >
    <Router>
      {/* The slug after the object is only for human readable URLs and discarded below */ }
      <ItemComp path="lediga-lokaler/:guid/*" />
    </Router>
  </Layout>
)

export default SingleFacility;
