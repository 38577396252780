import React from 'react'
import PropTypes from 'prop-types'
import {
  H2, H3, Container, Header, Footer, List, Item, Prefix, Emphasis, Description, Content, Arrow
} from './styles'
import createSrcSet from '../../utils/createSrcSet'
import createSizes from '../../utils/createSizes'

const FacilityListingItem = ({
  guid, objectType, area, streetAddress, yearlySqmRent, posterImage, noBackground, noHover, USP, settings
}) => {
  if (posterImage.thumbnail === null) {
    throw new Error('No thumbnail images')
  }

  const {
    headline,
    shortDescription,
    longDescription
  } = USP

  const { showRent } = settings

  const backgroundImage = {
    base64: posterImage.thumbnail.data,
    aspectRatio: posterImage.thumbnail.aspectRatio,
    src: posterImage.sizes[0].url,
    srcSet: createSrcSet(posterImage.sizes),
    sizes: createSizes`(max-width: ${'phone'}px) 100vw, (max-width: ${'large'}px) calc(50vw - ${'gutterSmall'} * 1.5), calc(33vw - ${'gutterSmall'} * 2)`
  }

  return (
    <Container noHover={noHover} fluid={backgroundImage} data-id={guid}>
      <Header>
        <H2>{headline}</H2>
        <H3>{streetAddress}</H3>
      </Header>
      <Footer>
        <Description>
          <p>{longDescription || shortDescription}</p>
          <div><Arrow /></div>
        </Description>
        <Content noBackground={noBackground}>
          <List>
            <Item>
              {showRent ? (
                <>
                  <Prefix>Hyra från:</Prefix>
                  <Emphasis>{`${yearlySqmRent} kr/kvm`}</Emphasis>
                </>
              ) : (
                <>
                  <Prefix>Hyra från:</Prefix>
                  <Emphasis>Vid förfrågan</Emphasis>
                </>
              )}
            </Item>
            <Item>
              <Prefix>Yta:</Prefix>
              <Emphasis>{`${area} kvm`}</Emphasis>
            </Item>
            <Item>
              <Prefix>Typ av lokal:</Prefix>
              <Emphasis>{objectType}</Emphasis>
            </Item>
          </List>
        </Content>
      </Footer>
    </Container>
  )
}

FacilityListingItem.propTypes = {
  guid: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  admittanceDate: PropTypes.string,
  yearlySqmRent: PropTypes.number.isRequired,
  posterImage: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  earliastAdmittance: PropTypes.string
}

FacilityListingItem.defaultProps = {
  admittanceDate: null,
  earliastAdmittance: 'Enligt överenskommelse'
}

export default FacilityListingItem