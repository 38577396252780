import React from 'react'
import Layout from '../../components/Layout'
import Button from '/src/components/Button/index.js'
import CustomLink from '/src/components/CustomLink/index.js'
import { Wrapper, Heading, Paragraph } from './styles'

const PageNotFound = () => (
  <Layout>
    <Wrapper>
      <Heading>Sidan hittades inte</Heading>
      <Paragraph>Denna sida verkar tyvärr inte existera... Gå till startsidan eller använd menyn för att navigera till den sida du letar efter</Paragraph>
      <CustomLink to="/">
        <Button
          text="Till startsidan"
        />
      </CustomLink>
    </Wrapper>
  </Layout>
)

export default PageNotFound
