import React, { useState, useEffect } from 'react'
import {
  outsideClick,
  toggleInquiry
} from '../../utils/helpers'
import {
  Wrapper,
  Heading,
  Form,
  StyledButton,
  SubmitMessage,
  Consent,
  ConsentContent,
  CheckboxWrapper,
  Checkbox,
  CheckboxCheck,
  Label,
  Message,
  Close,
  Content
} from './styles'
import {
  ERROR_SUBMIT_INQUIRY,
  SUCCESS_SUBMIT_INQUIRY,
  TERMS_AGREEMENT
} from '../../content'
import validateInputs from '../../utils/validateInputs'
import Input from '../Input'

const Inquiry = () => {
  const initialValues = {
    name: '',
    company: '',
    email: '',
    phone: '',
    additionalmessage: '',
    consent: false
  }

  const [values, setValues] = useState(initialValues)

  const [messages, setMessages] = useState({
    name: false,
    company: false,
    email: false,
    phone: false,
    additionalmessage: false,
    consent: false
  })

  const [loading, setLoading] = useState(false)

  const [submitStatus, setSubmitStatus] = useState('')

  // Effect to handle outside click
  useEffect(() => {
    // Listen for outside click and add handler
    // to close inquiry if outside click is detected
    const closeOnOutsideClick = outsideClick('#inquiry', toggleInquiry)
    closeOnOutsideClick.listen()

    // Add event listener for url hash change
    window.addEventListener('hashchange', toggleInquiry, false)

    return function cleanup() {
      // Remove click listener when not needed
      closeOnOutsideClick.remove()
    }
  })

  useEffect(() => {
    // Open Inquiry if there is a hash in the url on pageload
    toggleInquiry()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const errors = validateInputs(values)

    values.recipient = 'fredrik.linden@novier.se'

    if (!errors) {
      // Execute submit logic
      // Todo: Refactor to external request service
      // Todo: Add loding state

      try {
        const response = await fetch('/.netlify/functions/inquiry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
        const data = await response.text()

        if (data === 'success') {
          // Show success message and reset form on successful submissiom
          setSubmitStatus('success')
          setValues(initialValues)
        } else {
          setSubmitStatus('error')
        }
      } catch (error) {
        setSubmitStatus('error')
      }
    }
    setMessages(errors)
  }

  const handleChange = (target) => {
    const {
      name, value, checked, type,
    } = target
    const newValue = type === 'checkbox' ? checked : value

    setValues({
      ...values,
      [name]: newValue
    })
  }

  return (
    <Wrapper>
      <Content id="inquiry">
        <Close onClick={toggleInquiry} />
        <Form>
          <Heading>Vill du veta mer?</Heading>

          <Input
            label="Namn"
            name="name"
            onChange={handleChange}
            value={values.name}
            message={messages.name}
          />
          <Input
            label="Företag"
            name="company"
            onChange={handleChange}
            value={values.company}
            message={messages.company}
          />
          <Input
            label="Epost"
            name="email"
            onChange={handleChange}
            value={values.email}
            message={messages.email}
          />
          <Input
            label="Telefon"
            name="phone"
            onChange={handleChange}
            value={values.phone}
            message={messages.phone}
          />
          <Input
            id="message"
            type="textarea"
            label="Meddelande"
            name="additionalmessage"
            onChange={handleChange}
            value={values.additionalmessage}
            message={messages.additionalMessage}
            theme="dark"
          />
          <Consent>
            <ConsentContent>
              <CheckboxWrapper>
                <Checkbox
                  type="checkbox"
                  id="terms-agreement"
                  onChange={(e) => handleChange(e.target)}
                  name="consent"
                  value={values.consent}
                />
                <CheckboxCheck />
              </CheckboxWrapper>
              <Label htmlFor="terms-agreement">{TERMS_AGREEMENT}</Label>
            </ConsentContent>
            {messages.consent && <Message type={messages.consent.type}>{messages.consent.text}</Message>}
          </Consent>
          <StyledButton
            text="Skicka"
            onClick={(e) => handleSubmit(e)}
            loading={loading}
          />
          {submitStatus === 'error' && <SubmitMessage type="error">{ERROR_SUBMIT_INQUIRY}</SubmitMessage>}
          {submitStatus === 'success' && <SubmitMessage type="success">{SUCCESS_SUBMIT_INQUIRY}</SubmitMessage>}
        </Form>
      </Content>
    </Wrapper>
  )
}

export default Inquiry