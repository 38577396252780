import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  StyledHero,
  HeroInner,
  BackgroundImage,
  StyledVideo,
  Content,
  Title,
  LeftCol,
  ButtonWrapper
} from './styles'
import CTA from '../../CTA'

const Hero = ({
  title,
  backgroundImage,
  cta1Text,
  cta1Url,
  cta2Text,
  cta2Url,
  useVideo,
  backgroundVideo
}) => (
  <StyledHero>
    <HeroInner>
      {useVideo
        ? (
          <>
            {backgroundVideo
              && (
                <StyledVideo
                  url={backgroundVideo.url}
                  caption="Video caption"
                  autoPlay
                  playsInline
                />
              )}
          </>
        ) : (
          <>
            {backgroundImage
            && <BackgroundImage fluid={backgroundImage.fluid} />}
          </>
        )}
      <Content>
        <LeftCol>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <ButtonWrapper>
            {cta1Text && <CTA text={cta1Text} url={cta1Url} theme="cta" icon="dotted-arrow" />}
            {cta2Text && <CTA text={cta2Text} url={cta2Url} theme="dark" />}
          </ButtonWrapper>
        </LeftCol>
      </Content>
    </HeroInner>
  </StyledHero>
)

Hero.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.object
  }),
  cta1Text: PropTypes.string,
  cta1Url: PropTypes.string,
  cta2Text: PropTypes.string,
  cta2Url: PropTypes.string,
  useVideo: PropTypes.bool,
  backgroundVideo: PropTypes.shape({
    url: PropTypes.string
  }),
}

Hero.defaultProps = {
  title: '',
  backgroundImage: {
    fluid: null
  },
  cta1Text: '',
  cta1Url: '',
  cta2Text: '',
  cta2Url: '',
  useVideo: true,
  backgroundVideo: {
    url: ''
  },
}
export default Hero

export const HERO_BLOCK_FRAGMENT = graphql`
  fragment HeroBlockFragment on DatoCmsHeroBlock {
    title
    backgroundImage {
      fluid(maxWidth: 2560) {
        ...GatsbyDatoCmsFluid
      }
    }
    cta1Text
    cta1Url
    cta2Text
    cta2Url
    useVideo
    backgroundVideo {
      url
    }
  }  
`