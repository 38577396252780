import styled, { css } from 'styled-components'

export const Container = styled.article`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorAccent};
  pointer-events: none;
  fill: ${({ theme }) => theme.colorBlack};
  padding: 0.9em; 
`
export const IconStyle = css`
  fill: inherit;
  width: 3em;
  height: 3em;
`

export const Label = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
`

export const Radio = styled.input`
  display: none;
    
  &:checked + ${Container} {
    background: ${({ theme }) => theme.colorBlack};
    fill: #fff;
  }
`

export const Tooltip = styled.div`
  padding: ${({ theme }) => theme.gutterSmall};
  position: absolute;
  bottom: calc(100% + ${({ theme }) => theme.gutterSmall});
  left: 50%;
  transform: translate(-50%);
  display: none;
  background: ${({ theme }) => theme.colorWhite};
  border-radius: ${({ theme }) => theme.contentRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};

  ${Label}:hover & {
    display: block;
  }
`