import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import {
  StyledLink,
  themes 
} from './styles'
import { getIcon } from '../../layout/getIcon'

const CTA = ({
  text, url, theme, className, icon, target
}) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    // code to run on component mount

    // If icon is set to false return early
    if (!icon) return

    // Import based on icon prop 
    getIcon(icon)
    .then((module) => {
      const StyledIcon = styled(module.default)`
        fill: ${themes[theme].textColor};
        margin-left: ${({ theme }) => theme.buttonIconSize};
        width: ${({ theme }) => theme.buttonIconSize};
        height: ${({ theme }) => theme.buttonIconSize};
      `
      setIconComponent(StyledIcon)
    })
  }, [])

  return (
    <ThemeProvider theme={themes[theme]}>
      <StyledLink
        className={className}
        to={url}
        target={target}
      >
        {text}
        {IconComponent && <IconComponent />}
      </StyledLink>
    </ThemeProvider>
  )
}

CTA.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.string,
  target: PropTypes.string
}

CTA.defaultProps = {
  text: 'I am a CTA',
  url: '',
  theme: 'cta',
  icon: '',
  target: ''
}

export default CTA