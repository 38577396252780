import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  Radio,
  Container,
  IconStyle,
  Tooltip
} from './styles'
import styled from 'styled-components'
import { getIcon } from '../../layout/getIcon'

const IconOption = ({
  onChange,
  value,
  name,
  icon,
  checked,
  label,
  className
}) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    // code to run on component mount

    // If icon is set to false return early
    if (!icon) return

    // Import based on icon prop 
    getIcon(icon)
    .then((module) => {
      const StyledIcon = styled(module.default)([IconStyle])
      setIconComponent(StyledIcon)
    })
  }, [])
  
  return (
    <Label className={className}>
      <Radio
        type="radio"
        value={value}
        name={name}
        defaultChecked={checked}
        onChange={(event) => {
          onChange(event.target.value)
        }}
      />
      <Container>
        {IconComponent && <IconComponent />}
      </Container>
      <Tooltip>{label}</Tooltip>
    </Label>
  )
}

export default IconOption

IconOption.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  checked: PropTypes.bool
}

IconOption.defaultProps = {
  checked: false
}