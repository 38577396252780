import styled from 'styled-components'
import { breakpoint } from '../../../layout/mixins'

export const Section = styled.section``

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  position: relative;
  width: 100%;
  
  ${breakpoint.up('desktop')`
    width: 50%;
  `}
`

export const MosaicItem = styled(GridItem)`
  height: 100%;
  padding: ${({ theme }) => `calc(${theme.gutterSmall}/2)`};


  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => theme.gutterSmall};
    padding-right: ${({ theme }) => `calc(${theme.gutterSmall}/2)`};
  `}

  ${breakpoint.up('maxWrapper')`
    padding-left: 0;
  `}
`

export const AvaliableFacilitiesItem = styled(GridItem)`
  height: 100%;
  padding: ${({ theme }) => `calc(${theme.gutterSmall}/2)`};

  ${breakpoint.up('desktop')`
    padding: ${({ theme }) => theme.gutterSmall};
    padding-left: ${({ theme }) => `calc(${theme.gutterSmall}/2)`};
  `}

  ${breakpoint.up('maxWrapper')`
    padding-right: 0;
  `}
`
