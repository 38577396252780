import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProfileImage = styled.div`
  width: 4.26rem;
  height: 4.26rem;
  border-radius: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  margin-right: ${({ theme }) => theme.gutter};
`

export const InfoItem = styled.div`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};                
  font-size: ${({ theme }) => theme.fontsizeMobile};
  line-height: 1.143em;
  color: ${({ theme: { contactInfoTheme: { textColor } } }) => textColor};
  color: ${({ theme }) => theme.textColor};
`

export const StyledLink = styled(InfoItem)``