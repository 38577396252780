import styled from 'styled-components'
import Marker from '../../layout/icons/marker.svg'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //user-select: none;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  text-align: center;
  width: 10rem;
  height: 10px;
`

export const Title = styled.strong`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: ${({ theme }) => theme.fontsizeRoot};
  line-height: 1.1em;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 100%);
  width: 100%;
  left: 50%;
  display: block;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 1);
`

export const StyledMarker = styled(Marker)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 27px;
  user-select: none;
  transform: translate(-50%, -100%);
  &:hover {
    z-index: 1;
  }
`