import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Section,
  Grid,
  TextItem,
  ImageItem,
  Image,
  TextContent,
  ContactPerson,
  Locations,
  Location1,
  Location2,
  StyledCTA
} from './styles'
import Layout from '../../components/Layout'


const FeatureHightlight = ({ data: { datoCmsContactPage: {
  image,
  contactPerson,
  location1,
  location2,
  ctaText,
  seoMetaTags
} }}) => {

  return (
    <Layout seo={seoMetaTags.tags}>
      <Section>
        <Grid>
          <ImageItem>
            <Image fluid={image.fluid} />
          </ImageItem>
          <TextItem>
            <TextContent>
              <ContactPerson className="rte" dangerouslySetInnerHTML={{ __html: contactPerson }} />
              <Locations>
                <Location1 className="rte" dangerouslySetInnerHTML={{ __html: location1 }} />
                <Location2 className="rte" dangerouslySetInnerHTML={{ __html: location2 }} />
              </Locations>
            </TextContent>
          </TextItem>
          {ctaText && (
            <StyledCTA
              icon="dotted-arrow"
              text={ctaText}
              url="/kontakt#vill-du-veta-mer"
            />
          )}
        </Grid>
      </Section>
    </Layout>
  )
}

FeatureHightlight.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  contactPerson: PropTypes.string,
  location1: PropTypes.string,
  location2: PropTypes.string,
  ctaText: PropTypes.string
}

FeatureHightlight.defaultProps = {
  image: {
    fluid: {}
  },
  contactPerson: '',
  location1: '',
  location2: '',
  ctaText: ''
}

export default FeatureHightlight

export const query = graphql`
  query ContactQuery {
    datoCmsContactPage {
      image {
        fluid(maxWidth: 1280,) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactPerson
      location1
      location2
      ctaText
      seoMetaTags {
        tags
      }
    }
  }
`