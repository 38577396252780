import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  Grid,
  ImageItem,
  StyledBackgroundImage,
  ImageIndex,
  TextItem,
  TextContent,
  Title,
  BodyText,
  Description,
  List,
  Item,
  Prefix,
  Emphasis,
  CTAContent,
  DownloadLink,
  DownloadIcon,
  DownloadText,
  StyledCTA,
  StyledButton,
  TourButton,
  StyledThreeDViewer,
  Sharebar,
  PrintIcon,
  TwitterIcon,
  FacebookIcon,
  MailIcon,
  IconLink,
  UnderlineLink,
  ImagesShortLink,
  AltImagesShortLink
} from './styles'
import Slider from '../Slider'
import createSrcSet from '../../utils/createSrcSet'
import createSizes from '../../utils/createSizes'
import formateDate from '../../utils/formatDate'
import { toggleFacilityInquiry } from '../../utils/helpers'
import { Collapse } from "@mantine/core";
import Button from '../Button'

const FacilityHero = ({
  guid, images, objectType, area, yearlySqmRent, descriptions, earliastAdmittance, admittanceDate, links, files, settings, USP, scrollToImages
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showTour, setTourVisibility] = useState(false)
  const [openedPreamble, setOpenPreamble] = useState(false);
  const [collapsableDescription, SetCollapsableDescription] = useState(false);

  const floorPlanning = files.filter((file) => file.name && file.name.toLowerCase().includes('planritning'))
  const tourLink = links.filter((link) => link.url && link.url.toLowerCase().includes('https://theviewer.co'))

  const { showRent } = settings

  const transformedImages = images.map((image) => ({
    fluid: {
      // base64: image.thumbnail.data,
      // aspectRatio: image.thumbnail.aspectRatio,
      src: image.sizes[image.sizes.length - 1].url,
      srcSet: createSrcSet(image.sizes),
      sizes: createSizes`(max-width: ${'phone'}px) 100vw, (max-width: ${'large'}px) calc(50vw - ${'gutterSmall'} * 1.5), calc(33vw - ${'gutterSmall'} * 2)`
    }
  }))

  useEffect(() => {
    if (USP.longDescription.length > 200) {
      SetCollapsableDescription([
        USP.longDescription.substring(0, 200),
        USP.longDescription.substring(200)
      ]);
    }
  }, [USP]);

  return (
    <>
      <Section>
        { tourLink && tourLink[0] && (
        <StyledThreeDViewer
          linksToInteriors={[{url: tourLink[0].url}]}
          enabled={showTour}
          noCover
        />
        )}
        <Grid>
          <ImageItem reverse>
            <Slider onSlideChange={(index) => setCurrentIndex(index)}>
              {transformedImages.map((item) => (
                <StyledBackgroundImage key={item.fluid.src} fluid={item.fluid} />
              ))}
            </Slider>
            <ImagesShortLink>
              <Button onClick={() => scrollToImages()} text={`Alla bilder (${transformedImages.length})`} />
            </ImagesShortLink>
            {transformedImages.length > 1 && <ImageIndex reverse>{`${currentIndex + 1}/${transformedImages.length}`}</ImageIndex>}
            { tourLink && tourLink[0] && (
            <TourButton
              text="Starta 360-tour"
              icon="spinner"
              onClick={() => {
                setTourVisibility(false)
                setTourVisibility(true)
              }}
            />
            ) }
          </ImageItem>
          <TextItem reverse>
            <AltImagesShortLink>
                <Button theme="dark" onClick={() => scrollToImages()} text={`Alla bilder (${transformedImages.length})`} />
            </AltImagesShortLink>
            <TextContent>

              <Title>{`${objectType}, ${area} kvm`}</Title>
              {collapsableDescription ? (
                <Description>
                  {!openedPreamble ? `${collapsableDescription[0]}... ` : null}
                  <Collapse in={openedPreamble}>{USP.longDescription}</Collapse>
                  <UnderlineLink onClick={() => setOpenPreamble((o) => !o)}>
                    {openedPreamble ? `Visa mindre` : `Visa mer`}
                  </UnderlineLink>
                </Description>
              ) : (
                <Description>{USP.longDescription}</Description>
              )}
              <List>
                <Item>
                  <Prefix>Hyra från:</Prefix>
                  {showRent ? (
                    <Emphasis>
                      {`${yearlySqmRent} kr/m`}
                      <sup>2</sup>
                    </Emphasis>
                  ) : (
                    <Emphasis>Vid förfrågan</Emphasis>
                  )}

                </Item>
                <Item>
                  <Prefix>Yta:</Prefix>
                  <Emphasis>
                    {`${area} m`}
                    <sup>2</sup>
                  </Emphasis>
                </Item>
                <Item>
                  <Prefix>Inflytt:</Prefix>
                  <Emphasis>{admittanceDate != null ? formateDate(admittanceDate) : earliastAdmittance}</Emphasis>
                </Item>
              </List>

              {floorPlanning && floorPlanning[0] && (
                <DownloadLink href={floorPlanning[0].url} download>
                  <DownloadIcon />
                  <DownloadText>Planritning</DownloadText>
                </DownloadLink>
              )}

            </TextContent>
            <CTAContent>
              <StyledButton
                text="Intresseanmälan"
                onClick={() => toggleFacilityInquiry()}
                theme="dark"
              />
              <StyledCTA
                text="Ladda ner PDF"
                url={`https://novi.fastighet.vitec.net/Pages/PDF/GeneratePDF.aspx?GUID=${guid}&typ=CMLokal`}
                theme="white"
              />
            </CTAContent>
            <Sharebar>
              <IconLink target="_blank" href={`https://novi.fastighet.vitec.net/Pages/PDF/GeneratePDF.aspx?GUID=${guid}&typ=CMLokal`} title="Utskriftvänlig version">
                <PrintIcon />
              </IconLink>
              <IconLink href={`mailto:?subject=${objectType}, ${area} kvm - Det vita huset&body=${window.location.href}`} title="Maila länk">
                <MailIcon />
              </IconLink>
              <IconLink target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} title="Dela på Facebook">
                <FacebookIcon />
              </IconLink>
              <IconLink target="_blank" href={`https://twitter.com/intent/tweet?url=${window.location.href}`} title="Dela på Twitter">
                <TwitterIcon />
              </IconLink>
            </Sharebar>
          </TextItem>
        </Grid>
      </Section>
    </>
  )
}


FacilityHero.propTypes = {
  images: PropTypes.array.isRequired
}


export default FacilityHero