export const getAmenityIcon = icon => {
    switch (icon) {
        case "Balkong":
            return import("/src/layout/icons/amenities/balkong.svg")
        case "Besöksparkering":
            return import("/src/layout/icons/amenities/besoksparkering.svg")
        case "Biltvatt":
            return import("/src/layout/icons/amenities/biltvatt.svg")
        case "Cykelrum":
            return import("/src/layout/icons/amenities/cykelrum.svg")
        case "Dusch":
            return import("/src/layout/icons/amenities/dusch.svg")
        case "Elbilsplatser":
            return import("/src/layout/icons/amenities/elbilsplatser.svg")
        case "Fiberanslutning":
            return import("/src/layout/icons/amenities/fiberanslutning.svg")
        case "Garage (kall)":
            return import("/src/layout/icons/amenities/garage-kall.svg")
        case "Garage (varm)":
            return import("/src/layout/icons/amenities/garage-varm.svg")
        case "Gym":
            return import("/src/layout/icons/amenities/gym.svg")
        case "Kemtvatt":
            return import("/src/layout/icons/amenities/kemtvatt.svg")
        case "Kök/pentry":
            return import("/src/layout/icons/amenities/kok-pentry.svg")
        case "Konferens":
            return import("/src/layout/icons/amenities/konferens.svg")
        case "Kundparkering":
            return import("/src/layout/icons/amenities/kundparkering.svg")
        case "Lasthiss":
            return import("/src/layout/icons/amenities/lasthiss.svg")
        case "Lastkaj":
            return import("/src/layout/icons/amenities/lastkaj.svg")
        case "Lastport":
            return import("/src/layout/icons/amenities/lastport.svg")
        case "Mötesrum":
            return import("/src/layout/icons/amenities/motesrum.svg")
        case "Omklädningsrum":
            return import("/src/layout/icons/amenities/omkladningsrum.svg")
        case "Parkering":
            return import("/src/layout/icons/amenities/parkering.svg")
        case "Reception":
            return import("/src/layout/icons/amenities/reception.svg")
        case "Restaurang":
            return import("/src/layout/icons/amenities/restaurang.svg")
        case "Serverrum":
            return import("/src/layout/icons/amenities/serverrum.svg")
        case "Skyltfönster":
            return import("/src/layout/icons/amenities/skyltfonster.svg")
        case "Skyltläge":
            return import("/src/layout/icons/amenities/skyltlage.svg")
        case "Telefonbås":
            return import("/src/layout/icons/amenities/telefonbas.svg")
        case "Terrass":
            return import("/src/layout/icons/amenities/terrass.svg")
        case "Tvättservice":
            return import("/src/layout/icons/amenities/tvattservice.svg")
        case "Varuhiss":
            return import("/src/layout/icons/amenities/varuhiss.svg")
        case "Verkstad":
            return import("/src/layout/icons/amenities/verkstad.svg")
        case "WC":
            return import("/src/layout/icons/amenities/wc.svg")

        // Default
        default:
            return import("/src/layout/icons/amenities/generalAmenity.svg")
    }
  }
  