import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  breakpoint,
  ParagraphStyles,
  Heading1Styles,
  Heading2Styles,
  Heading3Styles,
  Heading4Styles
} from './mixins'
import theme from './theme'

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #fff;
  }

  html {
    font-family: ${theme.fontfamilyPrimary};
    font-size: ${theme.fontsizeMobile};

    ${breakpoint.up('tablet')`
      font-size: ${theme.fontsizeResponsive};
    `}

    ${breakpoint.up('max')`
      font-size: ${theme.fontsizeRoot};
    `}
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  // /* for sticky footer */

  .site-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${theme.maxWidthWrapper};
    margin: 0 auto;
  }



  .footer {
    flex-shrink: 0;
    flex-grow: 2;
  }

  /* end for sticky footer */
  
  body.menu-open {
    overflow: hidden; 

    ${breakpoint.up('desktop')`
      overflow: auto;
    `}
  }

  body.modal-open {
    overflow: hidden; 
  }

  body.inquiry-open,
  body.facility-inquiry-open {
    overflow: hidden; 
  }

  body.tour-open {
    overflow: hidden;
  }

  #modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${theme.zLevel4};
    pointer-events: none;

    .modal-open & {
      pointer-events: auto;
    }
  }

  main {
    position: relative;
    margin-top: ${theme.headerheightMobile};

    ${breakpoint.up('desktop')`
      margin-top: ${theme.headerheight};
    `}

  }

  .full-height main {
    min-height: calc(100vh - ${theme.headerheightMobile});

    ${breakpoint.up('desktop')`
      min-height: calc(100vh - ${theme.headerheight});
    `}
  }

  a {
    text-decoration: none;
    color: ${theme.textcolorPrimary};
  }

  a.active-link {
    background-color: ${({ theme }) => theme.colorAccent};
    padding: 5px 10px;
  }

  ul {
    padding-left: 0;
  }

  h1 {
    ${Heading1Styles}
  }

  h2 {
    ${Heading2Styles}
  }

  h3 {
    ${Heading3Styles}
  }

  h4 {
    ${Heading4Styles}
  }

  p {
    ${ParagraphStyles}
  }
  
  sup {
    font-size: 0.6em;
    vertical-align: super !important;
    line-height: 1 !important;
  }

  @keyframes bounce {
    from,
    12%,
    15%,
    18%,
    20%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
      transform: translate3d(0, 0, 0);
    }
  
    12% {
      transform: translate3d(-25px, 0, 0);
    }
  
    15% {
      transform: translate3d(10px, 0, 0);
    }
  
    18% {
      transform: translate3d(-5px, 0, 0);
    }

    20% {
      transform: translate3d(0, 0, 0);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }


`
export default GlobalStyles