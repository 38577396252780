import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Halign } from '../../../layout/mixins'
import {
  StyledText,
  Paragraph,
  Content
} from './styles'

const Text = ({
  text,
  textColor,
  backgroundColor,
  className
}) => (
  <StyledText
    className={className}
    backgroundColor={backgroundColor}
  >
    <Halign>
      <Content>
        <Paragraph
          dangerouslySetInnerHTML={{ __html: text }}
          textColor={textColor}
        />
      </Content>
    </Halign>
  </StyledText>
)

Text.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string
}

Text.defaultProps = {
  text: '',
  textColor: 'black',
  backgroundColor: 'transparent'
}

export default Text

export const TEXT_BLOCK_FRAGMENT = graphql`
  fragment TextBlockFragment on DatoCmsTextBlock {
    textContent
    backgroundColor {
      hex
    }
  }  
`
