import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { ButtonWrapper, ButtonText, themes } from './styles'
import { getIcon } from '../../layout/getIcon'

const Button = ({
  text, theme, icon, className, onClick
}) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    // code to run on component mount

    // If icon is set to false return early
    if (!icon) return

    // Import based on icon prop 
    getIcon(icon)
    .then((module) => {
      const StyledIcon = styled(module.default)`
        fill: ${themes[theme].textColor};
        margin-left: ${({ theme }) => theme.buttonIconSize};
        width: ${({ theme }) => theme.buttonIconSize};
        height: ${({ theme }) => theme.buttonIconSize};
      `
      setIconComponent(StyledIcon)
    })
  }, [])

  return (
    <ThemeProvider theme={themes[theme]}>
      <ButtonWrapper className={className} onClick={onClick}>
        <ButtonText>{ text }</ButtonText>
        {IconComponent && <IconComponent />}
      </ButtonWrapper>
    </ThemeProvider>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.string
}

Button.defaultProps = {
  text: 'I am a button',
  theme: 'cta',
  icon: ''
}

export default Button