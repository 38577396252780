import React from 'react'
import PropTypes from 'prop-types'
import { Container, Title, StyledMarker } from './styles'

const Marker = ({ text, onClick }) => (
  <Container
    alt={text}
    {...onClick ? { onClick } : {}}
  >
    <StyledMarker />

    { text ? (
      <Title>{text}</Title>
    ) : null }

  </Container>
)

Marker.defaultProps = {
  onClick: null,
  text: null
}

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default Marker