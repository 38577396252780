import React from 'react'
import nl2br from 'react-newline-to-break'
import {
  Heading, SubHeading, Content, ModalContent, ModalClose, Button, ButtonText, ButtonIcon
} from './styles'
import { Paragraph } from '../../layout/mixins'
import Toggle from '../Toggle'
import Modal from '../Modal'

const FacilityDescriptions = ({ facilityDesc, enviromentDesc }) => {
  const {
    headline,
    longDescrption,
    shortDescription
  } = facilityDesc

  const {
    general,
    architect,
    history,
    transportation,
    service,
    misc,
    environment,
    floorPlanning,
    parking
  } = enviromentDesc
  return (
    <>
      <Content>
        <Heading>Lokalbeskrivning</Heading>
        <SubHeading>{headline}</SubHeading>
        <Paragraph>{longDescrption || shortDescription}</Paragraph>
        { floorPlanning && (
          <>
            <SubHeading>Planlösning</SubHeading>
            <Paragraph>{floorPlanning}</Paragraph>
          </>
        )}

      </Content>
      <Content>
        <Heading>Fastighetsbeskrivning</Heading>
        <Paragraph>{general}</Paragraph>
      </Content>
      <Toggle>
        {(on, toggle) => (
          <>
            <Button
              onClick={toggle}
            >
              <ButtonText>Visa mer information</ButtonText>
              <ButtonIcon />
            </Button>
            {on && (
            <Modal
              hiddenClose
              toggle={toggle}
            >

              <ModalContent>
                <ModalClose onClick={toggle} />
                <Heading>Information</Heading>
                <SubHeading>Allmänt</SubHeading>
                <Paragraph>{misc}</Paragraph>
                <SubHeading>Omgivning</SubHeading>
                <Paragraph>{environment}</Paragraph>
                <SubHeading>Service</SubHeading>
                <Paragraph>{nl2br(service)}</Paragraph>
                <SubHeading>Kommunikationer</SubHeading>
                <Paragraph>{nl2br(transportation)}</Paragraph>
                <SubHeading>Parkering</SubHeading>
                <Paragraph>{parking}</Paragraph>
              </ModalContent>
            </Modal>
            )}
          </>
        )}
      </Toggle>
    </>
  )
}

export default FacilityDescriptions