import { SITE_URL } from '../config'

export const removeSiteDomain = (url) => url.split(SITE_URL)[1] || url


export const blockData = (blocks, blockName) => blocks
  .filter((block) => block['__typename'] === blockName)[0]

export const trimUnits = (value) => value.match(/(\d+(\.\d+)?)/)[0]

export const isExternalUrl = (url) => {
  const pattern = /^((http|https|www):\/\/)/
  return pattern.test(url)
}

export const isHashUrl = (url) => url.includes('#')

export const toggleInquiry = () => {
  if ((location.hash === '#vill-du-veta-mer') && document.body.classList.contains('inquiry-open')) {
    document.body.classList.remove('inquiry-open')
    history.replaceState(null, null, location.href.split('#')[0])
  } else if (location.hash === '#vill-du-veta-mer') {
    document.body.classList.add('inquiry-open')
  }

  if ((location.hash !== '#vill-du-veta-mer') && document.body.classList.contains('inquiry-open')) {
    document.body.classList.remove('inquiry-open')
  }
}

export const toggleFacilityInquiry = () => {
  document.body.classList.toggle('facility-inquiry-open')
}

export const isEmail = (email) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export const getIframeSrc = (providerUid) =>
  // TODO: Adjust to enable other providers
  `https://www.youtube.com/embed/${providerUid}`


export const outsideClick = (targets, callback) => {
  const handler = (evt) => {
    const insideElements = Array.from(document.querySelectorAll(targets))
    let targetElement = evt.target // clicked element

    do {
      if (insideElements.includes(targetElement)) {
        // Click inside.
        // Do nothing, just return.
        return
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode
    } while (targetElement)

    // Clicked outside. Do something useful here.
    if (typeof callback === 'function') {
      callback()
    }
  }

  const listen = () => {
    document.addEventListener('click', handler)
  }

  const remove = () => {
    document.removeEventListener('click', handler)
  }

  return {
    listen,
    remove
  }
}