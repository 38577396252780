import styled from 'styled-components'
import { breakpoint, Heading2 } from '../../layout/mixins'
import { StyledMessage } from '../Input/styles'
import CheckIcon from '../../layout/icons/check.svg'
import CloseIcon from '../../layout/icons/close.svg'
import Button from '../Button'

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zLevel4};
  display: none;
  transform: translate(-50%, -50%);
  max-width: ${({ theme }) => theme.maxWidthWrapper};

  .facility-inquiry-open & {
    display: flex;
    justify-content: center;
  }

  ${breakpoint.up('desktop')`
    z-index: ${({ theme }) => theme.zLevel3};
    padding-top: 0;
    height: auto;
  `}
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorBlack};
  position: relative;
  padding: ${({ theme }) => theme.gutter};
  padding-top: ${({ theme }) => theme.gutterXLarge};
  overflow-y: auto;

  ${breakpoint.up('desktop')`
    width: auto;
    height: auto;
    padding: ${({ theme }) => theme.gutterXLarge};
    padding-bottom: 0;
  `}
`


export const Form = styled.form`
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
`

export const Heading = styled(Heading2)`
  margin-bottom: ${({ theme }) => theme.gutter};
  color: ${({ theme }) => theme.colorWhite};
  font-size: 2rem;
`
export const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const SubmitMessage = styled(StyledMessage)`
  position: static;
  display: block;
  margin-top: ${({ theme }) => theme.gutter};
  margin-bottom: ${({ theme }) => theme.gutter};
  text-align: center;
  color: ${({ theme, type }) => (type === 'error' ? theme.colorDanger : theme.colorAccent)};
  font-size: 1em;
`

export const Consent = styled.div`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const ConsentContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const CheckboxWrapper = styled.div`
  position: relative;
`

export const CheckboxCheck = styled(CheckIcon)`
  position: absolute;
  top: 3px;
  left: 0;
  fill: ${({ theme }) => theme.colorAccent};
  transform: scale(1.2);
  pointer-events: none;
  display: none;

  input:checked + & {
    display: block;
  }
`

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colorWhite};
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:checked:before {
    display: none;
  }
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 0.72rem;
  padding-left: ${({ theme }) => theme.gutterSmall};
  cursor: pointer;
  color: ${({ theme }) => theme.colorWhite};

  a {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Message = styled(StyledMessage)`
  position: static;
  display: block;
  text-align: right;
`

export const Close = styled(CloseIcon)`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  stroke: ${({ theme }) => theme.colorWhite};
  width: 1.11rem;
  height: 1.11rem;
  cursor: pointer;
`