import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  InputGroup,
  StyledInput,
  TextAreaGroup,
  StyledTextArea,
  Label,
  StyledMessage,
  themes
} from './styles'

const Input = ({
  label, message, onChange, value, name, theme, type, placeholder, id
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const textArea = React.createRef()

  useEffect(
    () => {
      if (value) {
        setIsFocused(true)
      }
    },
    [],
  )

  const handleBlur = () => {
    if (value === '') setIsFocused(false)
  }

  return (
    <ThemeProvider theme={themes[theme]}>

      {type === 'input' && (
        <InputGroup>
          <Label isFocused={isFocused}>{label}</Label>
          <StyledInput
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onChange={(e) => onChange(e.target)}
            defaultValue={value}
            name={name}
          />
          {message && <StyledMessage type={message.type}>{message.text}</StyledMessage>}
        </InputGroup>
      )}

      {type === 'textarea' && (
        <TextAreaGroup>
          <Label htmlFor={id} isFocused={isFocused}>{label}</Label>
          <StyledTextArea
            id={id}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onChange={(e) => onChange(e.target)}
            defaultValue={value}
            name={name}
            placeholder={placeholder}
            ref={textArea}
          />
          {message && <StyledMessage type={message.type}>{message.text}</StyledMessage>}
        </TextAreaGroup>
      )}
    </ThemeProvider>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onChange: PropTypes.func,
  theme: PropTypes.string,
  value: PropTypes.string
}

Input.defaultProps = {
  type: 'input',
  label: 'Example field',
  message: '',
  onChange: () => {},
  theme: 'dark',
  value: ''
}

export default Input