import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { blockData } from '../../utils/helpers'
import FeatureHightlight, { FEATURE_HIGHLIGHT_BLOCK_FRAGMENT } from '../../components/blocks/FeatureHightlight'
import Article, { REPORTAGE_BLOCK_FRAGMENT } from '../../components/blocks/Article'
import Media, { MEDIA_BLOCK_FRAGMENT } from '../../components/blocks/Media'

const About = ({ data }) => {
  const {
    pageBlocks,
    seoMetaTags
  } = data.datoCmsPage


  return (
    <Layout seo={seoMetaTags.tags}>
      <FeatureHightlight {...pageBlocks[0]} />
      <Media {...blockData(pageBlocks, 'DatoCmsMediaBlock')} />
      <FeatureHightlight {...pageBlocks[1]} />
      {/* <FeatureHightlight {...blockData(pageBlocks, 'DatoCmsFeatureHighlight')}/>
      <FeatureHightlight {...blockData(pageBlocks, 'DatoCmsFeatureHighlight')}/> */}
      <Article
        {...blockData(pageBlocks, 'DatoCmsReportageBlock').reportage[0]}
        reverse={blockData(pageBlocks, 'DatoCmsReportageBlock').reverse}
      />
    </Layout>
  )
}

export default About;

export const query = graphql`
  query AboutQuery {
    datoCmsPage(id: {eq: "DatoCmsPage-1787809-sv"}) {
      pageBlocks {
        __typename
        ...FeatureHighlightBlockFragment
        ...ReportageBlockFragment
        ...MediaBlockFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`