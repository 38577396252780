import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'
import GlobalStyles from '../../layout/globalStyles'
import theme from '../../layout/theme'
import CSSReset from '../../layout/reset'
import RTEStyles from '../../layout/rteStyles'
import SEO from '../SEO'
import Header from '../Header'
import MenuMobile from '../MenuMobile'
import Inquiry from '../Inquiry'
import Footer from '../Footer'
import { removeSiteDomain } from '../../utils/helpers'
import { MantineProvider } from '@mantine/core';


if (typeof window !== 'undefined') {
  // kick off the polyfill!
  smoothscroll.polyfill()
}

export default ({ children, seo, disableFooter }) => {
  const navigationData = useStaticQuery(navigationQuery)

  // Normalize links from Graphql for our menu components
  const links = navigationData.datoCmsNavigation.links.map((link) => {
    if (link['__typename'] === 'DatoCmsLink') {
      return link
    }

    return {
      link: {
        title: link.title,
        slug: removeSiteDomain(link.url)
      }
    }
  })

  return (
    <MantineProvider>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <GlobalStyles />
        <RTEStyles />
        <SEO seoTags={seo} />
        {/* DOM structure and classes needed for sticky footer */}
        <div className="site-wrapper">
          <div className="content">
            <Inquiry />
            <Header menuItems={links} />
            <MenuMobile menuItems={links} />
            <main>{children}</main>
          </div>
          { disableFooter ? null : (
            <Footer />
          )}
        </div>
      </ThemeProvider>
    </MantineProvider>
  )
}

const navigationQuery = graphql`
  query NavigationQuery {
    datoCmsNavigation {
      links {
        ... on DatoCmsLink {
          __typename
          link {
            ... on DatoCmsContactPage {
              title
              slug
            }
            ... on DatoCmsPage {
              slug
              title
            }
          }
        }
        ... on DatoCmsUrl {
          title
          url
        }
      }
    }
  }
`