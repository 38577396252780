import React from 'react'
import { graphql } from 'gatsby'
import { blockData } from '../../utils/helpers'
import { StyledText } from './styles'
import Layout from '../../components/Layout'
import Hero, { HERO_BLOCK_FRAGMENT } from '../../components/blocks/Hero'
import ThreeDTour, { THREED_TOUR_BLOCK_FRAGMENT } from '../../components/blocks/ThreeDTour'
import { TEXT_BLOCK_FRAGMENT  } from '../../components/blocks/Text'
import Usp, { USP_BLOCK_FRAGMENT  } from '../../components/blocks/Usp'
import ReportageHighlight, { REPORTAGE_HIGHLIGHT_BLOCK_FRAGMENT  } from '../../components/blocks/ReportageHighlight'
import Geography from '../../components/blocks/Geography'

// Todo: Add defaults for null properties

const Index = ({ data }) => {
  const {
    pageBlocks,
    seoMetaTags
  } = data && data.datoCmsPage ? data.datoCmsPage : {}

  const {
    textContent,
    backgroundColor: {
      hex
    }
  } = blockData(pageBlocks, 'DatoCmsTextBlock')

  return (
    <Layout seo={seoMetaTags.tags}>
      <Hero {...blockData(pageBlocks, 'DatoCmsHeroBlock')} />
      <StyledText
        text={textContent}
        textColor="white"
        backgroundColor={hex}
      />
      <Usp {...blockData(pageBlocks, 'DatoCmsUspBlock')} />
      <ThreeDTour {...blockData(pageBlocks, 'DatoCmsThreedTourBlock')} />
      <ReportageHighlight
        {...blockData(pageBlocks, 'DatoCmsReportageHighlightBlock').reportage}
        reverse={blockData(pageBlocks, 'DatoCmsReportageHighlightBlock').reverse}
        linkUrl={blockData(pageBlocks, 'DatoCmsReportageHighlightBlock').linkUrl}
      />
      <Geography />
    </Layout>
  )
}

export default Index

export const query = graphql`
  query IndexQuery {
    datoCmsPage(id: {eq: "DatoCmsPage-1721668-sv"}) {
      pageBlocks {
        __typename
        ...HeroBlockFragment
        ...TextBlockFragment
        ...UspBlockFragment
        ...ReportageHighlightBlockFragment
        ...ThreedTourBlockFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`
