import React from 'react'
import {
  Arrow,
  Details,
  Header,
  Button,
  IconOptions,
  Content,
  Route,
  FromAddress,
  ToAddress,
  DirectionArrow,
  TimeToDestination,
  Distance,
  DirectionsLink
} from './styles'
import IconOption from '../IconOption'

const travelModeParameters = {
  WALKING: 'w',
  BICYCLING: 'b',
  DRIVING: 'd',
  TRANSIT: 'r'
}

export const DirectionDetails = ({
  travelOptions,
  setTravelMode,
  details,
  resetDestination,
  travelMode
}) => (
  <Details>
    <Header>
      <Button
        type="button"
        onClick={resetDestination}
      >
        <Arrow />
      Ändra adress
      </Button>
      <IconOptions>
        { travelOptions.map((option) => (
          <IconOption
              // eslint-disable-next-line react/jsx-props-no-spreading
            key={option.label}
            {...option}
            onChange={setTravelMode}
            checked={option.value === travelMode}
            name="traveloptions"
          />
        ))}
      </IconOptions>
    </Header>
      <Content>
        <Route>
          <FromAddress>{details?.endAddress?.split(',')[0]}</FromAddress>
          <DirectionArrow> → </DirectionArrow>
          <ToAddress>{details?.startAddress?.split(',')[0]}</ToAddress>
        </Route>
        <TimeToDestination>{details?.duration}</TimeToDestination>
        <Distance>{details?.distance}</Distance>
        <DirectionsLink
          href={`https://maps.google.com/?saddr=${details?.endAddress}&daddr=${details?.startAddress}&dirflg=${travelModeParameters[travelMode]}`}
          target="_blank"
        >
          Visa detaljerad vägbeskrivning i Google Maps 
          <DirectionArrow> →</DirectionArrow>
        </DirectionsLink>
      </Content>
  </Details>
)

export default DirectionDetails