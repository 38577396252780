import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Halign } from '../../../layout/mixins'
import {
  Section,
  Grid,
  TextItem,
  MediaItem,
  Iframe,
  Image,
  TextContent,
  Title,
  BodyText
} from './styles'
import Video from '../../Video'

const Media = ({
  title,
  content,
  image,
  useVideo,
  video,
  reverse
}) => {

  return (
    <Section>
      <Halign>
        <Grid>
          <TextItem reverse={reverse}>
            <TextContent reverse={reverse}>
              <Title as="h2">{title}</Title>
              <BodyText className="rte" dangerouslySetInnerHTML={{ __html: content }} />
            </TextContent>
          </TextItem>
          <MediaItem reverse={reverse}>
            {/* {useVideo
              ? (
                <>
                  {video
                    && (
                      <Video
                        url={video.url}
                        caption="Video caption"
                        autoPlay={false}
                        showPlayButton={true}
                      />
                    )}
                </>
              ) : (
                <>
                  {image
                  && <Image fluid={image.fluid} />}
                </>
              )} */}
                    <Iframe
                      src="https://www.youtube.com/embed/iEr4aXGCM8U"
                      frameBorder="0"
                      allowFullScreen
                      >
                    </Iframe>
          </MediaItem>
        </Grid>
      </Halign>
    </Section>
  )
}

Media.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.object
  }),
  useVideo: PropTypes.bool,
  video: PropTypes.shape({
    url: PropTypes.string
  }),
  reverse: PropTypes.bool
}

Media.defaultProps = {
  title: '',
  content: '',
  image: {
    fluid: {}
  },
  useVideo: false,
  video: {
    url: ''
  },
  reverse: false
}

export default Media

export const MEDIA_BLOCK_FRAGMENT = graphql`
  fragment MediaBlockFragment on DatoCmsMediaBlock {
    title
    content
    image {
      fluid(maxWidth: 1280) {
        ...GatsbyDatoCmsFluid
      }
    }
    useVideo
    video {
      url
    }
    reverse
  }
`