import styled from 'styled-components'
import {
  BlockPaddingBoth,
  ParagraphLarge,
  breakpoint
} from '../../../layout/mixins'

export const StyledText = styled(BlockPaddingBoth)`
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
`

export const Paragraph = styled(ParagraphLarge)`
  color: ${({ textColor }) => textColor};
  text-align: center;

  ${breakpoint.up('desktop')`
    font-size: 1.6rem;
  `}

  & p {
    text-align: center;
  }
`