import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint, ParagraphLargeStyles } from '../../layout/mixins'
import SwitchIcon from '../../layout/icons/switch.svg'
import Button from '../Button'
import Spinner from '../Spinner'

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  ${({ visble }) => visble === false && `
    display: none;
  `}

  ${breakpoint.up('tablet')`
    padding-top: 0;
    height: 50vw;
  `}

  ${breakpoint.up('desktop')`
    transition: height 0.2s ease-out;
    max-height: calc(100vh - 72px);
  `}

  .tour-open & {
    ${breakpoint.down('desktop')`
      position: fixed;
      top: 0;
      left: 0;
      padding-top: 0;
      height: 100vh;
      z-index: ${({ theme }) => theme.zLevel3};
    `}

    ${breakpoint.up('desktop')`
      height: calc(100vh - 72px);
    `}
  }
`

export const Inner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
`

export const StyledSpinner = styled(Spinner)`
  pointer-events: none;
  opacity: 0;
  transition: none;

  ${({ loading }) => loading && `
    opacity: 1;
  `}

  ${({ tourEnabled }) => tourEnabled && `
    transition: opacity .2s linear;
  `}

  ${({ loaded }) => loaded && `
    opacity: 0;
    transition: none;
  `}

  svg {
    ${breakpoint.up('desktop')`
      width: 3rem;
      height: 3rem;
    `}
  }
`

export const BackgroundImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const OverlayContent = styled.div``

export const CornerImageWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
    width: 6rem;
    height: 6rem;
  `}

  ${breakpoint.up('desktop')`
    display: block;
    width: 10rem;
    height: 10rem;
  `}
`

export const CornerImage = styled(Img)`
  width: 100%;
  height: 100%;
`

export const ContentCenter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StartButton = styled(Button)`
`

export const UIButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colorWhite};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  color: ${({ theme }) => theme.colorBlack};
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;

  ${breakpoint.up('tablet')`
    font-size: 1.33rem;
  `}
`

export const ChangeSrcButton = styled(UIButton)`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  left: ${({ theme }) => theme.gutter};
  z-index: ${({ theme }) => theme.zLevel1};
`

export const QuitButton = styled(UIButton)`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  z-index: ${({ theme }) => theme.zLevel1};
`

export const ChangeSrcIcon = styled(SwitchIcon)`
  fill: ${({ theme }) => theme.colorBlack};
  margin-left: ${({ theme }) => theme.gutter};
`

export const Instructions = styled.div`
  & p {
    ${ParagraphLargeStyles}
  }
  
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: ${({ theme }) => theme.gutter};
  text-align: center;
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  display: ${({ tourEnabled, theme }) => (tourEnabled ? 'block' : 'none')};
`